import React, { useRef, useState, createRef, useEffect, useLayoutEffect, useMemo } from "react";
import { Table, Card, Button, CardBody, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane, CardText, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, Row, FormFeedback, CardFooter, Modal, ModalBody } from "reactstrap";
import Select from "react-select";
import "../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
const ExcelJS = require('exceljs');
import * as XLSX from 'xlsx';
import '../../assets/css/custom.css';
import { useSelector, useDispatch } from "react-redux";
import { getLoggedInUser, getRefreshToken } from "helpers/fakebackend_helper";
import { getqamasterlist } from "store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as url from '../../helpers/url_helper'
const ImportExcel = () => {

    const user = getLoggedInUser()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useSelector(state => state.ExamBoardCategory)

    const [data, setData] = React.useState([]);
    const [list, setList] = React.useState([]);
    const [insertList, setInsertList] = React.useState([]);

    const [duplicateRecord, setDuplicateRecord] = React.useState(0);
    const [errorRecords, setErrorRecords] = React.useState(0);
    const [noOfQuestions, setNoOfQuestions] = React.useState(0);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [shuffle, setShuffle] = useState(true);
    const [repeatedQuestionIDs, setRepeatedQuestionIDs] = useState([]);
    const [duplicateCounts, setDuplicateCounts] = useState([]);

    const [optionSubjectName, setOptionSubjectName] = useState([]);
    const [optionSubjectSubcatName, setOptionSubjectSubcatName] = useState([]);
    const [optionExamLevel, setOptionExamLevel] = useState([]);
    const [optionTopicName, setOptionTopicName] = useState([]);
    const [optionExamPaper, setOptionExamPaper] = useState([]);
    const [optionsSubjectSubcategory, setOptionsSubjectSubcategory] = useState([]);
    const [optionAllSubjectName, setOptionAllSubjectName] = useState([]);
    const [optionAllSubjectSubcategoryName, setOptionAllSubjectSubcategoryName] = useState([]);
    const [optionAllTopicName, setOptionAllTopicName] = useState([]);
    const [optionAllExamPaper, setOptionAllExamPaper] = useState([]);
    const [optionAllLanguages, setOptionAllLanguages] = useState([]);
    const [selectedTopicName, setselectedTopicName] = useState(null);
    const [selectedSubjectName, setselectedSubjectName] = useState(null);
    const [selectedSubjectSubcatName, setselectedSubjectSubcatName] = useState(null);
    const [selectedExamLevel, setselectedExamLevel] = useState(null);
    const [selectedExamPaper, setselectedExamPaper] = useState(null);
    const [selectedLanguage, setselectedLanguage] = useState({ label: 'All', value: 0 });
    const [disabledsubcat, setDisabledsubcat] = useState(false);
    const [disabledtopic, setDisabledTopic] = useState(false);
    const [previewFlag, setPreviewFlag] = useState(false);
    const [importFlag, setImportFlag] = useState(false);
    const [dataFlag, setDataFlag] = useState(false);
    const [tamilHeader, setTamilHeader] = useState(false);
    const [headerText, setHeaderText] = useState(false);
    const [fileName, setFileName] = useState(false);

    const [expaperLanguageList, setExpaperLanguageList] = useState(false);

    const english_header = [
        'S.No.',
        'Question ID',
        'Exam Paper',
        'Subject',
        'Subject Subcategory',
        'Topic',
        'Exam Level',
        'Shuffle',
        'English Question Text',
        'English Option 1',
        'English Option 2',
        'English Option 3',
        'English Option 4',
        'English Option 5',
        'English Correct Option',
        'English Notes'
    ];

    const tamil_header = [
        'S.No.',
        'Question ID',
        'Exam Paper',
        'Subject',
        'Subject Subcategory',
        'Topic',
        'Exam Level',
        'Shuffle',
        'Regional Question Text',
        'Regional Option 1',
        'Regional Option 2',
        'Regional Option 3',
        'Regional Option 4',
        'Regional Option 5',
        'Regional Correct Option',
        'Regional Notes'
    ];

    const tamil_header1 = [
        'S.No.',
        'Question ID',
        'Exam Paper',
        'Subject',
        'Subject Subcategory',
        'Topic',
        'Exam Level',
        'Shuffle',
        'Regional Question Text',
        'Regional Option 1',
        'Regional Option 2',
        'Regional Option 3',
        'Regional Option 4',
        'Regional Option 5',
        'Regional Correct Option',
        'Regional Notes',
        'Regional Approve',
        'Regional Reject Reason',
        'Regional Question ID',
        'Regional Option 1 ID',
        'Regional Option 2 ID',
        'Regional Option 3 ID',
        'Regional Option 4 ID',
        'Regional Option 5 ID'
    ];

    const english_header1 = [
        'S.No.',
        'Question ID',
        'Exam Paper',
        'Subject',
        'Subject Subcategory',
        'Topic',
        'Exam Level',
        'Shuffle',
        'English Question Text',
        'English Option 1',
        'English Option 2',
        'English Option 3',
        'English Option 4',
        'English Option 5',
        'English Correct Option',
        'English Notes',
        'English Approve',
        'English Reject Reason',
        'English Question ID',
        'English Option 1 ID',
        'English Option 2 ID',
        'English Option 3 ID',
        'English Option 4 ID',
        'English Option 5 ID'
    ];

    const tamil_english_header1 = [
        'S.No.',
        'Question ID',
        'Exam Paper',
        'Subject',
        'Subject Subcategory',
        'Topic',
        'Exam Level',
        'Shuffle',
        'English Question Text',
        'English Option 1',
        'English Option 2',
        'English Option 3',
        'English Option 4',
        'English Option 5',
        'English Correct Option',
        'English Notes',
        'English Approve',
        'English Reject Reason',
        'Regional Question Text',
        'Regional Option 1',
        'Regional Option 2',
        'Regional Option 3',
        'Regional Option 4',
        'Regional Option 5',
        'Regional Correct Option',
        'Regional Notes',
        'Regional Approve',
        'Regional Reject Reason',
        'English Question ID',
        'English Option 1 ID',
        'English Option 2 ID',
        'English Option 3 ID',
        'English Option 4 ID',
        'English Option 5 ID',
        'Regional Question ID',
        'Regional Option 1 ID',
        'Regional Option 2 ID',
        'Regional Option 3 ID',
        'Regional Option 4 ID',
        'Regional Option 5 ID'
    ];

    useEffect(() => {
        getmasterlistapi()
        getmasterdatalistapi()
        const approvel_import = localStorage.getItem('QAApprovelImport');
        setImportFlag(Number(approvel_import))
        if (Number(approvel_import) === 1) {
            document.getElementById('headerTitle').innerHTML = 'Q&A Approval Import Excel'
        } else {
            document.getElementById('headerTitle').innerHTML = 'Q&A Import Excel'
        }
    }, []);

    const getmasterlistapi = () => {
        dispatch(getqamasterlist({ user_id: Number(user.uid) }));
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            selectedExamPaper: (selectedExamPaper) || undefined,
            selectedSubjectName: (selectedSubjectName) || undefined,
            selectedExamLevel: (selectedExamLevel) || undefined,
            selectedLanguage: (selectedLanguage) || undefined
        },
        validationSchema: Yup.object({
            selectedExamPaper: Yup.object().required("Please Select Exam Paper"),
            selectedSubjectName: Yup.object().required("Please Select Subject Name"),
            selectedExamLevel: Yup.object().required("Please Select Exam Level"),
            selectedLanguage: Yup.object().required("Please Select Language"),
        }),
        onSubmit: (values) => {
            exportToExcel()
            // const data = {
            //     "approval_flag": 0
            // }
            // handleSaveClick(data);
        },
    });

    useEffect(() => {
        console.log('dsfsdgdfgd', store.masterlist);
        if (store.masterlist != undefined) {
            console.log('dsfsdgdfgd');

            if (store.masterlist && store.masterlist.examLevelList)
                setOptionExamLevel(store.masterlist.examLevelList)
        }
    }, [store.masterlist])

    const getmasterdatalistapi = async () => {
        // setLoading(true)
        // dispatch(getmasterlist({ user_id: Number(user.uid) }));
        const data = { user_id: Number(user.uid) }
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'master/getMaster', data, config)

        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    await getmasterdatalistapi() // calling same function again getting refresh token
                }
            }
        }

        if (response1 && response1.data && response1.data && response1.data.status === 200) {

            setOptionAllSubjectName(response1?.data?.body?.subjectList)
            setOptionSubjectName([])
            setOptionAllSubjectSubcategoryName(response1?.data?.body?.subCategryList)
            setOptionSubjectSubcatName([])
            setOptionAllExamPaper(response1?.data?.body?.examPaperList)
            setOptionExamPaper(response1?.data?.body?.examPaperList)
            setOptionAllTopicName(response1?.data?.body?.topicList)
            setOptionTopicName([])
            setOptionAllLanguages([{ l_name: "All", lid: "0" }, ...response1?.data?.body?.languageList])
            // setExpaperLanguageList(response1?.data?.body?.languageList)
        }
    }

    const handleSelectPaper = (selectedOption) => {
        setselectedExamPaper(selectedOption)
        setDisabledsubcat(false)
        setDisabledTopic(false)
        var filter_subject = optionAllSubjectName.filter(e => e.expid == selectedOption?.value)
        var filter_language = optionExamPaper.filter(e => e.expid == selectedOption?.value)
        console.log('filter_language', filter_language);

        if (Number(filter_language[0]?.lang) === 1) {
            setselectedLanguage({
                label: "English", value: 1
            })
        }
        if (Number(filter_language[0]?.lang) === 2) {
            setselectedLanguage({
                label: "Regional", value: 2
            })
        }
        if (filter_language[0].lang) {
            // Convert the string to an array
            const langArray = filter_language[0]?.lang.split(','); // ['1', '2']

            // Check if both values exist in the array
            const includesOne = langArray.includes('1');
            const includesTwo = langArray.includes('2');
            if (includesOne && includesTwo) {
                setselectedLanguage({
                    label: "All", value: 0
                })
            }
        }
        setOptionSubjectName(filter_subject)
        setselectedSubjectName(null)
        setselectedSubjectSubcatName(null)
        setselectedTopicName(null)
        validation.setFieldValue("selectedSubjectName", null);
        validation.setFieldValue('selectedSubjectSubcatName', "")
        validation.setFieldValue("selectedTopicName", null);
    };

    function handleSelectSubjectName(varselectedSubjectName) {

        var filter_subcat = optionAllSubjectSubcategoryName.filter(e => e.ssc_sid == varselectedSubjectName?.value)
        setOptionSubjectSubcatName(filter_subcat)
        var filter_topic = optionAllTopicName.filter(e => e.subjectid == varselectedSubjectName?.value)
        setOptionTopicName(filter_topic);
        if (filter_topic.length == 0) {
            setDisabledTopic(true)
        } else {
            setDisabledTopic(false)
        }
        setselectedSubjectName(varselectedSubjectName);
        setselectedTopicName(null)
        validation.setFieldValue("selectedTopicName", null);
        if (filter_subcat?.length === 0) {
            setselectedSubjectSubcatName("")
            validation.setFieldValue('selectedSubjectSubcatName', "")
            setDisabledsubcat(true)
        } else {
            setDisabledsubcat(false)
        }
    }

    function handleSelectSubjectSubcatName(varselectedSubjectName) {
        setselectedSubjectSubcatName(varselectedSubjectName)
        setselectedTopicName('')
        var topic_filter = optionAllTopicName.filter((e) => Number(e?.subcategoryid) === Number(varselectedSubjectName?.value))
        console.log('optionAllTopicName', optionAllTopicName);
        console.log('topic_filter', topic_filter);
        console.log('varselectedSubjectName?.value', varselectedSubjectName?.value);
        setOptionTopicName(topic_filter);
        if (topic_filter.length === 0) {
            setDisabledTopic(true)
        } else {
            setDisabledTopic(false)
        }
    }

    function handleSelectTopicName(varselectedTopicName) {
        setselectedTopicName(varselectedTopicName);
    }

    // for template download
    const exportToExcel = async () => {

        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Q&A List')

        const approvel_import = localStorage.getItem('QAApprovelImport')

        console.log('selectedLanguage', selectedLanguage);
        console.log('approvel_import', approvel_import);

        var headerRow = []


        if (Number(selectedLanguage.value) === Number(0)) {
            // Add headers with styling
            headerRow = worksheet.addRow([
                'S.No.',
                'Question ID',
                'Exam Paper',
                'Subject',
                'Subject Subcategory',
                'Topic',
                'Exam Level',
                'Shuffle',
                'English Question Text',
                'English Option 1',
                'English Option 2',
                'English Option 3',
                'English Option 4',
                'English Option 5',
                'English Correct Option',
                'English Notes',
                'Regional Question Text',
                'Regional Option 1',
                'Regional Option 2',
                'Regional Option 3',
                'Regional Option 4',
                'Regional Option 5',
                'Regional Correct Option',
                'Regional Notes'
            ])

            headerRow.font = { bold: true }
            headerRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'c0d5e8' } // Background color
            }

            // Create an array from 1 to 100
            const numbers = Array.from({ length: 2 }, (_, index) => index + 1);

            console.log('numbers', numbers);


            numbers.forEach((item, index) => {
                worksheet.addRow([
                    index + 1,
                    '',
                    selectedExamPaper?.label,
                    selectedSubjectName?.label,
                    selectedSubjectSubcatName?.label ? selectedSubjectSubcatName?.label : '',
                    selectedTopicName?.label ? selectedTopicName?.label : '',
                    selectedExamLevel?.label,
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    ''
                ])
            })

            worksheet.getColumn(1).width = 5;
            worksheet.getColumn(2).width = 12;
            worksheet.getColumn(3).width = 20;
            worksheet.getColumn(4).width = 20;
            worksheet.getColumn(5).width = 20;
            worksheet.getColumn(6).width = 20;
            worksheet.getColumn(7).width = 20;
            worksheet.getColumn(8).width = 10;
            worksheet.getColumn(9).width = 24;
            worksheet.getColumn(10).width = 20;
            worksheet.getColumn(11).width = 20;
            worksheet.getColumn(12).width = 20;
            worksheet.getColumn(13).width = 20;
            worksheet.getColumn(14).width = 20;
            worksheet.getColumn(15).width = 24;
            worksheet.getColumn(16).width = 20;
            worksheet.getColumn(17).width = 24;
            worksheet.getColumn(18).width = 20;
            worksheet.getColumn(19).width = 20;
            worksheet.getColumn(20).width = 20;
            worksheet.getColumn(21).width = 20;
            worksheet.getColumn(22).width = 20;
            worksheet.getColumn(23).width = 24;
            worksheet.getColumn(24).width = 20;

            // Lock all cells by default
            worksheet.eachRow((row) => {
                row.eachCell((cell) => {
                    cell.protection = { locked: true };
                });
            });

            // Unlock specific columns (H, I, J, K, L, M, N, P) for the first 20 rows
            const columnsToUnlock = ['H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Z'];
            for (let rowIndex = 1; rowIndex <= 3; rowIndex++) {
                columnsToUnlock.forEach(column => {
                    const cell = worksheet.getCell(`${column}${rowIndex}`);
                    cell.protection = { locked: false }; // Unlock specific cells
                });
            }

            // Protect the worksheet
            worksheet.protect('12345', {
                selectLockedCells: false, // Users cannot select locked cells
                selectUnlockedCells: true  // Users can select unlocked cells
            });
        }
        else {

            var language = selectedLanguage?.label;

            console.log('language', language);

            // Add headers with styling
            headerRow = worksheet.addRow([
                'S.No.',
                'Question ID',
                'Exam Paper',
                'Subject',
                'Subject Subcategory',
                'Topic',
                'Exam Level',
                'Shuffle',
                language + ' Question Text',
                language + ' Option 1',
                language + ' Option 2',
                language + ' Option 3',
                language + ' Option 4',
                language + ' Option 5',
                language + ' Correct Option',
                language + ' Notes'
            ])

            headerRow.font = { bold: true }
            headerRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'c0d5e8' } // Background color
            }

            // Create an array from 1 to 100
            const numbers = Array.from({ length: 3 }, (_, index) => index + 1);

            console.log('numbers', numbers);


            numbers.forEach((item, index) => {
                worksheet.addRow([
                    index + 1,
                    '',
                    selectedExamPaper?.label,
                    selectedSubjectName?.label,
                    selectedSubjectSubcatName?.label ? selectedSubjectSubcatName?.label : '',
                    selectedTopicName?.label ? selectedTopicName?.label : '',
                    selectedExamLevel?.label,
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                ])
            })

            worksheet.getColumn(1).width = 5;
            worksheet.getColumn(2).width = 12;
            worksheet.getColumn(3).width = 20;
            worksheet.getColumn(4).width = 20;
            worksheet.getColumn(5).width = 20;
            worksheet.getColumn(6).width = 20;
            worksheet.getColumn(7).width = 11;
            worksheet.getColumn(8).width = 10;
            worksheet.getColumn(9).width = 24;
            worksheet.getColumn(10).width = 20;
            worksheet.getColumn(11).width = 20;
            worksheet.getColumn(12).width = 20;
            worksheet.getColumn(13).width = 20;
            worksheet.getColumn(14).width = 20;
            worksheet.getColumn(15).width = 24;
            worksheet.getColumn(16).width = 20;

            // Lock all cells by default
            worksheet.eachRow((row) => {
                row.eachCell((cell) => {
                    cell.protection = { locked: true };
                });
            });

            // Unlock specific columns (H, I, J, K, L, M, N, P) for the first 20 rows
            const columnsToUnlock = ['H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'Q', 'P'];
            for (let rowIndex = 1; rowIndex <= 3; rowIndex++) {
                columnsToUnlock.forEach(column => {
                    const cell = worksheet.getCell(`${column}${rowIndex}`);
                    cell.protection = { locked: false }; // Unlock specific cells
                });
            }

            // Protect the worksheet
            worksheet.protect('12345', {
                selectLockedCells: false, // Users cannot select locked cells
                selectUnlockedCells: true  // Users can select unlocked cells
            });
        }

        // Define a solid fill color
        const fillColor = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00' } }; // Solid color

        // Define border style
        const borderStyle = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
        };

        for (let i = 2; i <= 3; i++) {
            const cellA = worksheet.getCell(`A${i}`);
            const cellB = worksheet.getCell(`B${i}`);
            const cellC = worksheet.getCell(`C${i}`);
            const cellD = worksheet.getCell(`D${i}`);
            const cellE = worksheet.getCell(`E${i}`);
            const cellF = worksheet.getCell(`F${i}`);
            const cellG = worksheet.getCell(`G${i}`);

            // Fill cells with color
            cellA.fill = fillColor;
            cellB.fill = fillColor;
            cellC.fill = fillColor;
            cellD.fill = fillColor;
            cellE.fill = fillColor;
            cellF.fill = fillColor;
            cellG.fill = fillColor;

            // Apply borders
            cellA.border = borderStyle;
            cellB.border = borderStyle;
            cellC.border = borderStyle;
            cellD.border = borderStyle;
            cellE.border = borderStyle;
            cellF.border = borderStyle;
            cellG.border = borderStyle;

            cellA.protection = { locked: true }; 
            cellB.protection = { locked: true }; 
            cellC.protection = { locked: true }; 
            cellD.protection = { locked: true }; 
            cellE.protection = { locked: true }; 
            cellF.protection = { locked: true }; 
            cellG.protection = { locked: true }; 
           
        }

        // Save the workbook
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `Q&A List.xlsx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const handleFileUpload = (e) => {
        console.log('setData');
        const file = e.target.files[0];
        setFileName(event.target.files[0]?.name);
        const reader = new FileReader();

        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet);
            console.log('setData', sheetData);
            setData(sheetData);
            setDataFlag(true);
            setTotalRecords(sheetData?.length);
            setInsertList(sheetData)
            setList([])

            // Reset the input value to allow the same file to be uploaded again
            e.target.value = null; // Resetting the input value
        };
        reader.readAsBinaryString(file);
    };

    // useEffect(() => {
    const excel_bulkupload_data_prepare = () => {

        var questions = [];
        var q_options = [];
        var q_texts = [];
        insertList?.forEach((row, index) => {
            if (row['Exam Paper']) {
                var exp_row = optionAllExamPaper.find(e => e.exp_name === row['Exam Paper']);
                console.log('exp_row', exp_row);
                row['Exam Paper'] = Number(exp_row?.expid);
            }
            if (row['Subject']) {
                var sub_row = optionAllSubjectName.find(e => e.subjectname === row['Subject']);
                console.log('sub_row', sub_row);
                row['Subject'] = Number(sub_row?.subjectid);
            }
            if (row['Subject Subcategory']) {
                var subcat_row = optionAllSubjectSubcategoryName.find(e => e.ssc_name === row['Subject Subcategory']);
                console.log('subcat_row', subcat_row);
                row['Subject Subcategory'] = subcat_row ? Number(subcat_row?.sscid) : 0;
            }
            if (row['Topic']) {
                var topic_row = optionAllTopicName.find(e => e.topicname === row['Topic']);
                console.log('topic_row', topic_row);
                row['Topic'] = topic_row ? Number(topic_row?.topicid) : 0;
            }
            if (row['Exam Level']) {
                var explevel_row = optionExamLevel.find(e => e.levelname === row['Exam Level']);
                console.log('explevel_row', explevel_row);
                row['Exam Level'] = Number(explevel_row?.levelid);
            }

            var yes = 'YES';
            var no = 'NO';

            if (row['Shuffle'] && typeof row['Shuffle'] === 'string') {
                if (typeof row['Shuffle'] === 'string' && row['Shuffle'].toLocaleLowerCase() === yes.toLocaleLowerCase()) {
                    row['Shuffle'] = 1;
                }
                if (typeof row['Shuffle'] === 'string' && row['Shuffle'].toLocaleLowerCase() === no.toLocaleLowerCase()) {
                    row['Shuffle'] = 0;
                }
            }

            if (Object.keys(data[0]).length > 17) {

                if (row['Regional Question Text'] || row['English Question Text']) {

                    const new_question = {
                        "q_row_id": index + 1,
                        "q_exid": 0,
                        "q_exctid": 0,
                        "q_approval_flag": 0,
                        "q_stsid": 1,
                        "q_vstatus": 3,
                        "q_extid": 1,
                        "q_sid": row["Subject"],
                        "q_sscid": row["Subject Subcategory"] ? row["Subject Subcategory"] : 0,
                        "q_tid": row["Topic"] ? row["Topic"] : 0,
                        "q_exlid": row["Exam Level"],
                        "q_expid": row["Exam Paper"],
                        "q_shuffle": row["Shuffle"]
                    }

                    questions.push(new_question);
                }

                if (row['Regional Question Text']) {
                    const new_option = [{
                        "q_row_id": index + 1,
                        "qa_name": row['Regional Option 1'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 1'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    }, {
                        "q_row_id": index + 1,
                        "qa_name": row['Regional Option 2'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 2'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    },
                    {
                        "q_row_id": index + 1,
                        "qa_name": row['Regional Option 3'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 3'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    },
                    {
                        "q_row_id": index + 1,
                        "qa_name": row['Regional Option 4'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 4'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    },
                    {
                        "q_row_id": index + 1,
                        "qa_name": row['Regional Option 5'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 5'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    }
                    ]

                    const new_question_text = [{
                        "q_row_id": index + 1,
                        "qt_contentflag": 0,
                        "qt_text": row['Regional Question Text'],
                        "qt_langflag": 2,
                        "qt_notes_contentflag": 0,
                        "qt_notes": row['Regional Notes']
                    }
                    ]

                    q_options.push(new_option);
                    q_texts.push(new_question_text);
                }

                if (row['English Question Text']) {
                    const new_option = [
                        {
                            "q_row_id": index + 1,
                            "qa_name": row['English Option 1'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 1'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        }, {
                            "q_row_id": index + 1,
                            "qa_name": row['English Option 2'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 2'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        },
                        {
                            "q_row_id": index + 1,
                            "qa_name": row['English Option 3'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 3'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        },
                        {
                            "q_row_id": index + 1,
                            "qa_name": row['English Option 4'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 4'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        },
                        {
                            "q_row_id": index + 1,
                            "qa_name": row['English Option 5'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 5'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        }
                    ]

                    const new_question_text = [
                        {
                            "q_row_id": index + 1,
                            "qt_contentflag": 0,
                            "qt_text": row['English Question Text'],
                            "qt_langflag": 1,
                            "qt_notes_contentflag": 0,
                            "qt_notes": row['English Notes']
                        }
                    ]

                    q_options.push(new_option);
                    q_texts.push(new_question_text);
                }

            } else {

                console.log('else part', row);
                console.log('insertList', insertList);


                var headerText = '';
                headerText = tamilHeader ? "Regional" : "English";
                console.log('headerText', "" + headerText + " Option 1");
                console.log('Option1', row["" + headerText + " Option 1"]);

                const new_question = {
                    "q_row_id": index + 1,
                    "q_exid": 0,
                    "q_exctid": 0,
                    "q_approval_flag": 0,
                    "q_stsid": 1,
                    "q_vstatus": 3,
                    "q_extid": 1,
                    "q_sid": row["Subject"],
                    "q_sscid": row["Subject Subcategory"],
                    "q_tid": row["Topic"],
                    "q_exlid": row["Exam Level"],
                    "q_ex_paper_id": row["Exam Paper"],
                    "q_shuffle": row["Shuffle"]
                }

                const new_option = [{
                    "q_row_id": index + 1,
                    "qa_name": row["" + headerText + " Option 1"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 1"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                }, {
                    "q_row_id": index + 1,
                    "qa_name": row["" + headerText + " Option 2"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 2"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                },
                {
                    "q_row_id": index + 1,
                    "qa_name": row["" + headerText + " Option 3"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 3"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                },
                {
                    "q_row_id": index + 1,
                    "qa_name": row["" + headerText + " Option 4"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 4"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                },
                {
                    "q_row_id": index + 1,
                    "qa_name": row["" + headerText + " Option 5"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 5"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                }]

                const new_question_text = {
                    "q_row_id": index + 1,
                    "qt_contentflag": 0,
                    "qt_text": row["" + headerText + " Question Text"],
                    "qt_langflag": tamilHeader ? 2 : 1,
                    "qt_notes_contentflag": 0,
                    "qt_notes": row["" + headerText + " Notes"]
                }

                // console.log('new_question', new_question);
                console.log('new_option', new_option);

                if (row["" + headerText + " Question Text"]) {
                    questions.push(new_question);
                    q_options.push(new_option);
                    q_texts.push(new_question_text);
                }
            }
        })

        console.log('q_options', q_options);
        var questionDetails = [{
            "questions": questions,
            "q_options": q_options,
            "q_texts": q_texts.flat()
        }]
        return questionDetails;
    }

    const update_excel_bulkupload_data_prepare = () => {

        var questions = [];
        var q_options = [];
        var q_texts = [];
        insertList?.forEach((row, index) => {
            // if (row['Exam Paper']) {
            //     var exp_row = optionAllExamPaper.find(e => e.exp_name === row['Exam Paper']);
            //     console.log('exp_row', exp_row);
            //     row['Exam Paper'] = Number(exp_row?.expid);
            // }
            // if (row['Subject']) {
            //     var sub_row = optionAllSubjectName.find(e => e.subjectname === row['Subject']);
            //     console.log('sub_row', sub_row);
            //     row['Subject'] = Number(sub_row?.subjectid);
            // }
            // if (row['Subject Subcategory']) {
            //     var subcat_row = optionAllSubjectSubcategoryName.find(e => e.ssc_name === row['Subject Subcategory']);
            //     console.log('subcat_row', subcat_row);
            //     row['Subject Subcategory'] = Number(subcat_row?.sscid);
            // }
            // if (row['Topic']) {
            //     var topic_row = optionAllTopicName.find(e => e.topicname === row['Topic']);
            //     console.log('topic_row', topic_row);
            //     row['Topic'] = Number(topic_row?.topicid);
            // }
            // if (row['Exam Level']) {
            //     var explevel_row = optionExamLevel.find(e => e.levelname === row['Exam Level']);
            //     console.log('explevel_row', explevel_row);
            //     row['Exam Level'] = Number(explevel_row?.levelid);
            // }

            var yes = 'YES';
            var no = 'NO';

            if (row['Shuffle'] && typeof row['Shuffle'] === 'string') {
                if (typeof row['Shuffle'] === 'string' && row['Shuffle'].toLocaleLowerCase() === yes.toLocaleLowerCase()) {
                    row['Shuffle'] = 1;
                }
                if (typeof row['Shuffle'] === 'string' && row['Shuffle'].toLocaleLowerCase() === no.toLocaleLowerCase()) {
                    row['Shuffle'] = 0;
                }
            }

            if (Object.keys(data[0]).length > 17) {

                if (row['Regional Question Text'] || row['English Question Text']) {

                    const new_question = {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "q_shuffle": row["Shuffle"]
                    }

                    questions.push(new_question);
                }

                if (row['Regional Question Text']) {
                    const new_option = [{
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["Regional Option 1 ID"]),
                        "qa_name": row['Regional Option 1'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 1'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    }, {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["Regional Option 2 ID"]),
                        "qa_name": row['Regional Option 2'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 2'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    },
                    {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["Regional Option 3 ID"]),
                        "qa_name": row['Regional Option 3'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 3'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    },
                    {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["Regional Option 4 ID"]),
                        "qa_name": row['Regional Option 4'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 4'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    },
                    {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["Regional Option 5 ID"]),
                        "qa_name": row['Regional Option 5'],
                        "qa_contentflag": 0,
                        "qa_answerflag": row['Regional Option 5'] == row['Regional Correct Option'] ? 1 : 0,
                        "qa_langflag": 2,
                    }
                    ]

                    const new_question_text = [{
                        "q_row_id": index + 1,
                        "qt_qid": Number(row['Regional Question ID']),
                        "qt_contentflag": 0,
                        "qt_text": row['Regional Question Text'],
                        "qt_langflag": 2,
                        "qt_notes_contentflag": 0,
                        "qt_notes": row['Regional Notes']
                    }
                    ]

                    q_options.push(new_option);
                    q_texts.push(new_question_text);
                }

                if (row['English Question Text']) {
                    const new_option = [
                        {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["English Option 1 ID"]),
                            "qa_name": row['English Option 1'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 1'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        }, {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["English Option 2 ID"]),
                            "qa_name": row['English Option 2'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 2'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        },
                        {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["English Option 3 ID"]),
                            "qa_name": row['English Option 3'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 3'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        },
                        {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["English Option 4 ID"]),
                            "qa_name": row['English Option 4'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 4'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        },
                        {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["English Option 5 ID"]),
                            "qa_name": row['English Option 5'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['English Option 5'] == row['English Correct Option'] ? 1 : 0,
                            "qa_langflag": 1,
                        }
                    ]

                    const new_question_text = [
                        {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qt_qid": Number(row["English Question ID"]),
                            "qt_contentflag": 0,
                            "qt_text": row['English Question Text'],
                            "qt_langflag": 1,
                            "qt_notes_contentflag": 0,
                            "qt_notes": row['English Notes']
                        }
                    ]

                    q_options.push(new_option);
                    q_texts.push(new_question_text);
                }

            } else {

                console.log('else part', row);
                console.log('insertList', insertList);


                var headerText = '';
                headerText = tamilHeader ? "Regional" : "English";
                console.log('headerText', "" + headerText + " Option 1");
                console.log('Option1', row["" + headerText + " Option 1"]);

                const new_question = {
                    "q_row_id": index + 1,
                    "qid": Number(row["Question ID"]),
                    "q_shuffle": row["Shuffle"]
                }

                const new_option = [{
                    "q_row_id": index + 1,
                    "qid": Number(row["Question ID"]),
                    "qa_qid": Number(row["" + headerText + " Option 1 ID"]),
                    "qa_name": row["" + headerText + " Option 1"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 1"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                }, {
                    "q_row_id": index + 1,
                    "qid": Number(row["Question ID"]),
                    "qa_qid": Number(row["" + headerText + " Option 2 ID"]),
                    "qa_name": row["" + headerText + " Option 2"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 2"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                },
                {
                    "q_row_id": index + 1,
                    "qid": Number(row["Question ID"]),
                    "qa_qid": Number(row["" + headerText + " Option 3 ID"]),
                    "qa_name": row["" + headerText + " Option 3"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 3"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                },
                {
                    "q_row_id": index + 1,
                    "qid": Number(row["Question ID"]),
                    "qa_qid": Number(row["" + headerText + " Option 4 ID"]),
                    "qa_name": row["" + headerText + " Option 4"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 4"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                },
                {
                    "q_row_id": index + 1,
                    "qid": Number(row["Question ID"]),
                    "qa_qid": Number(row["" + headerText + " Option 5 ID"]),
                    "qa_name": row["" + headerText + " Option 5"],
                    "qa_contentflag": 0,
                    "qa_answerflag": row["" + headerText + " Option 5"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                    "qa_langflag": 1,
                }]

                const new_question_text = {
                    "q_row_id": index + 1,
                    "qt_qid": Number(row["" + headerText + " Question ID"]),
                    "qt_contentflag": 0,
                    "qt_text": row["" + headerText + " Question Text"],
                    "qt_langflag": tamilHeader ? 2 : 1,
                    "qt_notes_contentflag": 0,
                    "qt_notes": row["" + headerText + " Notes"]
                }

                // console.log('new_question', new_question);
                console.log('new_option', new_option);

                if (row["" + headerText + " Question Text"]) {
                    questions.push(new_question);
                    q_options.push(new_option);
                    q_texts.push(new_question_text);
                }
            }
        })

        console.log('q_options', q_options);
        var questionDetails = [{
            "questions": questions,
            "q_options": q_options,
            "q_texts": q_texts.flat()
        }]
        return questionDetails;
    }

    const approve_update_excel_bulkupload_data_prepare = () => {

        var questions = [];
        var q_options = [];
        var q_texts = [];
        insertList?.forEach((row, index) => {
            // if (row['Exam Paper']) {
            //     var exp_row = optionAllExamPaper.find(e => e.exp_name === row['Exam Paper']);
            //     console.log('exp_row', exp_row);
            //     row['Exam Paper'] = Number(exp_row?.expid);
            // }
            // if (row['Subject']) {
            //     var sub_row = optionAllSubjectName.find(e => e.subjectname === row['Subject']);
            //     console.log('sub_row', sub_row);
            //     row['Subject'] = Number(sub_row?.subjectid);
            // }
            // if (row['Subject Subcategory']) {
            //     var subcat_row = optionAllSubjectSubcategoryName.find(e => e.ssc_name === row['Subject Subcategory']);
            //     console.log('subcat_row', subcat_row);
            //     row['Subject Subcategory'] = Number(subcat_row?.sscid);
            // }
            // if (row['Topic']) {
            //     var topic_row = optionAllTopicName.find(e => e.topicname === row['Topic']);
            //     console.log('topic_row', topic_row);
            //     row['Topic'] = Number(topic_row?.topicid);
            // }
            // if (row['Exam Level']) {
            //     var explevel_row = optionExamLevel.find(e => e.levelname === row['Exam Level']);
            //     console.log('explevel_row', explevel_row);
            //     row['Exam Level'] = Number(explevel_row?.levelid);
            // }

            var yes = 'YES';
            var no = 'NO';

            if (row['Shuffle'] && typeof row['Shuffle'] === 'string') {
                if (typeof row['Shuffle'] === 'string' && row['Shuffle'].toLocaleLowerCase() === yes.toLocaleLowerCase()) {
                    row['Shuffle'] = 1;
                }
                if (typeof row['Shuffle'] === 'string' && row['Shuffle'].toLocaleLowerCase() === no.toLocaleLowerCase()) {
                    row['Shuffle'] = 0;
                }
            }

            if (row['English Approve'] && typeof row['English Approve'] === 'string') {
                if (typeof row['English Approve'] === 'string' && row['English Approve'].toLocaleLowerCase() === yes.toLocaleLowerCase()) {
                    row['English Approve'] = 1;
                }
                if (typeof row['English Approve'] === 'string' && row['English Approve'].toLocaleLowerCase() === no.toLocaleLowerCase()) {
                    row['English Approve'] = 0;
                }
            }

            if (row['Regional Approve'] && typeof row['Regional Approve'] === 'string') {
                if (typeof row['Regional Approve'] === 'string' && row['Regional Approve'].toLocaleLowerCase() === yes.toLocaleLowerCase()) {
                    row['Regional Approve'] = 1;
                }
                if (typeof row['Regional Approve'] === 'string' && row['Regional Approve'].toLocaleLowerCase() === no.toLocaleLowerCase()) {
                    row['Regional Approve'] = 0;
                }
            }

            if (Object.keys(data[0]).length > 17) {

                if (row['Regional Approve'] || row['English Approve']) {

                    if (row['Regional Question Text'] || row['English Question Text']) {

                        const new_question = {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "q_shuffle": row["Shuffle"],
                            "q_vstatus": row["English Approve"] === 1 ? 4 : row['Regional Approve'] === 1 ? 4 : 5,
                            "q_reason": row["English Approve"] === 0 ? row['English Reject Reason'] : row['Regional Approve'] ? row['Regional Reject Reason'] : ''
                        }

                        questions.push(new_question);
                    }

                    if (row['Regional Question Text']) {
                        const new_option = [{
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["Regional Option 1 ID"]),
                            "qa_name": row['Regional Option 1'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['Regional Option 1'] == row['Regional Correct Option'] ? 1 : 0,
                            "qa_langflag": 2,
                        }, {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["Regional Option 2 ID"]),
                            "qa_name": row['Regional Option 2'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['Regional Option 2'] == row['Regional Correct Option'] ? 1 : 0,
                            "qa_langflag": 2,
                        },
                        {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["Regional Option 3 ID"]),
                            "qa_name": row['Regional Option 3'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['Regional Option 3'] == row['Regional Correct Option'] ? 1 : 0,
                            "qa_langflag": 2,
                        },
                        {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["Regional Option 4 ID"]),
                            "qa_name": row['Regional Option 4'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['Regional Option 4'] == row['Regional Correct Option'] ? 1 : 0,
                            "qa_langflag": 2,
                        },
                        {
                            "q_row_id": index + 1,
                            "qid": Number(row["Question ID"]),
                            "qa_qid": Number(row["Regional Option 5 ID"]),
                            "qa_name": row['Regional Option 5'],
                            "qa_contentflag": 0,
                            "qa_answerflag": row['Regional Option 5'] == row['Regional Correct Option'] ? 1 : 0,
                            "qa_langflag": 2,
                        }
                        ]

                        const new_question_text = [{
                            "q_row_id": index + 1,
                            "qt_qid": Number(row['Regional Question ID']),
                            "qt_contentflag": 0,
                            "qt_text": row['Regional Question Text'],
                            "qt_langflag": 2,
                            "qt_notes_contentflag": 0,
                            "qt_notes": row['Regional Notes'],
                            "qt_approve_status": row['Regional Approve'] === 1 ? 1 : 5
                        }
                        ]

                        q_options.push(new_option);
                        q_texts.push(new_question_text);
                    }

                    if (row['English Question Text']) {
                        const new_option = [
                            {
                                "q_row_id": index + 1,
                                "qid": Number(row["Question ID"]),
                                "qa_qid": Number(row["English Option 1 ID"]),
                                "qa_name": row['English Option 1'],
                                "qa_contentflag": 0,
                                "qa_answerflag": row['English Option 1'] == row['English Correct Option'] ? 1 : 0,
                                "qa_langflag": 1,
                            }, {
                                "q_row_id": index + 1,
                                "qid": Number(row["Question ID"]),
                                "qa_qid": Number(row["English Option 2 ID"]),
                                "qa_name": row['English Option 2'],
                                "qa_contentflag": 0,
                                "qa_answerflag": row['English Option 2'] == row['English Correct Option'] ? 1 : 0,
                                "qa_langflag": 1,
                            },
                            {
                                "q_row_id": index + 1,
                                "qid": Number(row["Question ID"]),
                                "qa_qid": Number(row["English Option 3 ID"]),
                                "qa_name": row['English Option 3'],
                                "qa_contentflag": 0,
                                "qa_answerflag": row['English Option 3'] == row['English Correct Option'] ? 1 : 0,
                                "qa_langflag": 1,
                            },
                            {
                                "q_row_id": index + 1,
                                "qid": Number(row["Question ID"]),
                                "qa_qid": Number(row["English Option 4 ID"]),
                                "qa_name": row['English Option 4'],
                                "qa_contentflag": 0,
                                "qa_answerflag": row['English Option 4'] == row['English Correct Option'] ? 1 : 0,
                                "qa_langflag": 1,
                            },
                            {
                                "q_row_id": index + 1,
                                "qid": Number(row["Question ID"]),
                                "qa_qid": Number(row["English Option 5 ID"]),
                                "qa_name": row['English Option 5'],
                                "qa_contentflag": 0,
                                "qa_answerflag": row['English Option 5'] == row['English Correct Option'] ? 1 : 0,
                                "qa_langflag": 1,
                            }
                        ]

                        const new_question_text = [
                            {
                                "q_row_id": index + 1,
                                "qid": Number(row["Question ID"]),
                                "qt_qid": Number(row["English Question ID"]),
                                "qt_contentflag": 0,
                                "qt_text": row['English Question Text'],
                                "qt_langflag": 1,
                                "qt_notes_contentflag": 0,
                                "qt_notes": row['English Notes'],
                                "qt_approve_status": row['English Approve'] === 1 ? 1 : 5
                            }
                        ]

                        q_options.push(new_option);
                        q_texts.push(new_question_text);
                    }
                }

            } else {

                console.log('else part', row);
                console.log('insertList', insertList);


                var headerText = '';
                headerText = tamilHeader ? "Regional" : "English";
                console.log('headerText', "" + headerText + " Option 1");
                console.log('Option1', row["" + headerText + " Option 1"]);

                if (row['Regional Approve'] || row['English Approve']) {

                    const new_question = {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "q_shuffle": row["Shuffle"],
                        "q_vstatus": row["English Approve"] === 1 ? 4 : row['Regional Approve'] === 1 ? 4 : 5,
                        "q_reason": row["English Approve"] === 0 ? row['English Reject Reason'] : row['Regional Approve'] ? row['Regional Reject Reason'] : ''
                    }

                    const new_option = [{
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["" + headerText + " Option 1 ID"]),
                        "qa_name": row["" + headerText + " Option 1"],
                        "qa_contentflag": 0,
                        "qa_answerflag": row["" + headerText + " Option 1"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                        "qa_langflag": 1,
                    }, {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["" + headerText + " Option 2 ID"]),
                        "qa_name": row["" + headerText + " Option 2"],
                        "qa_contentflag": 0,
                        "qa_answerflag": row["" + headerText + " Option 2"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                        "qa_langflag": 1,
                    },
                    {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["" + headerText + " Option 3 ID"]),
                        "qa_name": row["" + headerText + " Option 3"],
                        "qa_contentflag": 0,
                        "qa_answerflag": row["" + headerText + " Option 3"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                        "qa_langflag": 1,
                    },
                    {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["" + headerText + " Option 4 ID"]),
                        "qa_name": row["" + headerText + " Option 4"],
                        "qa_contentflag": 0,
                        "qa_answerflag": row["" + headerText + " Option 4"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                        "qa_langflag": 1,
                    },
                    {
                        "q_row_id": index + 1,
                        "qid": Number(row["Question ID"]),
                        "qa_qid": Number(row["" + headerText + " Option 5 ID"]),
                        "qa_name": row["" + headerText + " Option 5"],
                        "qa_contentflag": 0,
                        "qa_answerflag": row["" + headerText + " Option 5"] == row["" + headerText + " Correct Option"] ? 1 : 0,
                        "qa_langflag": 1,
                    }]

                    const new_question_text = {
                        "q_row_id": index + 1,
                        "qt_qid": Number(row["" + headerText + " Question ID"]),
                        "qt_contentflag": 0,
                        "qt_text": row["" + headerText + " Question Text"],
                        "qt_langflag": tamilHeader ? 2 : 1,
                        "qt_notes_contentflag": 0,
                        "qt_notes": row["" + headerText + " Notes"],
                        "qt_approve_status": row['"" + headerText + " Approve'] === 1 ? 1 : 5
                    }

                    // console.log('new_question', new_question);
                    console.log('new_option', new_option);

                    if (row["" + headerText + " Question Text"]) {
                        questions.push(new_question);
                        q_options.push(new_option);
                        q_texts.push(new_question_text);
                    }
                }
            }
        })

        console.log('q_options', q_options);
        var questionDetails = [{
            "questions": questions,
            "q_options": q_options,
            "q_texts": q_texts.flat()
        }]
        return questionDetails;
    }

    // console.log('insertList', insertList);
    // previewExcel()
    // setInsertList(insert_data)
    // }, [insertList])

    const columns = useMemo(
        () => [
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Question ID ▼
                    </span>
                ),

                accessorKey: 'Question ID',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Exam Paper ▼
                    </span>
                ),

                accessorKey: 'Exam Paper',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Subject ▼
                    </span>
                ),

                accessorKey: 'Subject',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Subject Subcategory ▼
                    </span>
                ),

                accessorKey: 'Subject Subcategory',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Topic ▼
                    </span>
                ),

                accessorKey: 'Topic',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Exam Level ▼
                    </span>
                ),

                accessorKey: 'Exam Level',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Shuffle ▼
                    </span>
                ),

                accessorKey: 'Shuffle',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Question Text ▼
                    </span>
                ),

                accessorKey: 'Question Text',
                enableColumnFilter: false,
                enableSorting: true,
                // cell: (cellProps) => {
                //     var question_text = '';
                //     const question = cellProps.row.original.question;
                //     const created = cellProps.row.original.qid;

                //     if (question.length > 50) {
                //         var question_text = question.substring(0, 50 - 3) + '...';
                //     }

                //     return (
                //         <div className="d-flex align-items-center" id={'question' + created}>
                //             {question_text !== '' ? question_text : question}
                //             {question_text !== '' ? <UncontrolledTooltip placement="top" target={'question' + created}>
                //                 {question}
                //             </UncontrolledTooltip> : ''}
                //         </div>
                //     );
                // },
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Option 1 ▼
                    </span>
                ),

                accessorKey: 'Option 1',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Option 2 ▼
                    </span>
                ),

                accessorKey: 'Option 2',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Option 3 ▼
                    </span>
                ),

                accessorKey: 'Option 3',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Option 4 ▼
                    </span>
                ),

                accessorKey: 'Option 4',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Option 5 ▼
                    </span>
                ),

                accessorKey: 'Option 5',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Correct Option ▼
                    </span>
                ),

                accessorKey: 'Correct Option',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Language ▼
                    </span>
                ),

                accessorKey: 'Language',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Notes ▼
                    </span>
                ),

                accessorKey: 'Notes',
                enableColumnFilter: false,
                enableSorting: true,
            }
        ],
        []
    );

    const previewExcel = () => {

        var count = 0;
        const approvel_import = localStorage.getItem('QAApprovelImport');
        // correct option check
        data.forEach((row) => {

            // option is empty or not check when question is no empty

            if (row['English Question Text']) {
                if (!row['English Option 1'] || !row['English Option 2'] || !row['English Option 3'] || !row['English Option 4'] || !row['English Correct Option']) {
                    count = count + 1;
                }
            }

            if (row['Regional Question Text']) {
                if (!row['Regional Option 1'] || !row['Regional Option 2'] || !row['Regional Option 3'] || !row['Regional Option 4'] || !row['Regional Correct Option']) {
                    count = count + 1;
                }
            }


            // 5th option and correct question is same check
            if (row['Regional Option 5'] && row['Regional Correct Option']) {
                if (row['Regional Option 5'] === row['Regional Correct Option']) {
                    count = count + 1;
                }
            }
            if (row['English Option 5'] && row['English Correct Option']) {
                if (row['English Option 5'] === row['English Correct Option']) {
                    count = count + 1;
                }
            }

            if (Number(approvel_import) === 1) {
                var no = 'NO';

                // check reject reason is not empty when approve status is no
                if (row['English Approve'] && typeof row['English Approve'] === 'string' || row['Regional Approve'] && typeof row['Regional Approve'] === 'string') {

                    if (typeof row['English Approve'] === 'string' && row['English Approve'].toLocaleLowerCase() === no.toLocaleLowerCase() && !row['English Reject Reason'] || typeof row['Regional Approve'] === 'string' && row['Regional Approve'].toLocaleLowerCase() === no.toLocaleLowerCase() && !row['Regional Reject Reason']) {
                        console.log('jhvjhvjbgj');
                        count = count + 1;
                    }
                }

                if (!row['English Approve'] || !row['Regional Approve']) {
                    count = count + 1;
                }
            }
        })

        console.log('count', count);
        console.log('data', data);

        setNoOfQuestions(data?.length)
        setErrorRecords(count)
        // if (previewFlag) {
        setList(data)
        // }

    }

    const saveQuestionsExcel = async () => {
        console.log('errorRecords', errorRecords, 'noOfQuestions', noOfQuestions);

        let list_bulkupload;

        if (shuffle) {
            list_bulkupload = excel_bulkupload_data_prepare();
        } else {
            list_bulkupload = update_excel_bulkupload_data_prepare();
        }

        if (errorRecords === 0 && noOfQuestions > 0) {
            const user = getLoggedInUser()
            var data = {
                "user_id": Number(user.uid),
                "process_type": shuffle ? 'save' : 'update',
                "questions": list_bulkupload[0]?.questions,
                "q_options": list_bulkupload[0]?.q_options.flat(),
                "q_texts": list_bulkupload[0]?.q_texts
            }
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
            const response1 = await axios.post(url.BASE_URL + 'qa/saveQAExcel', data, config)
            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        saveQuestionsExcel() // calling same function again getting refresh token
                    }
                }
            }
            if (response1 && response1.data && response1.data.body) {
                if (response1.data.body.statusFlag === 1) {
                    toast.success(response1.data.body.message, { autoClose: 2000 })
                }
            } else {
                return { statusFlag: 2, message: 'Something went wrong' }
            }
        } else {
            toast.error('Please click preview to identify errors', { autoClose: 2000 })
        }
    }

    const approveQuestionsExcel = async () => {
        console.log('errorRecords', errorRecords, 'noOfQuestions', noOfQuestions);

        let list_bulkupload;

        if (importFlag === 1) {
            list_bulkupload = approve_update_excel_bulkupload_data_prepare();
        }

        if (errorRecords === 0 && noOfQuestions > 0) {
            const user = getLoggedInUser()
            var data = {
                "user_id": Number(user.uid),
                "process_type": 'update',
                "questions": list_bulkupload[0]?.questions,
                "q_options": list_bulkupload[0]?.q_options.flat(),
                "q_texts": list_bulkupload[0]?.q_texts
            }
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
            const response1 = await axios.post(url.BASE_URL + 'qa/updateQAExcel', data, config)
            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        approveQuestionsExcel() // calling same function again getting refresh token
                    }
                }
            }
            if (response1 && response1.data && response1.data.body) {
                if (response1.data.body.statusFlag === 1) {
                    toast.success(response1.data.body.message, { autoClose: 2000 })
                }
            } else {
                return { statusFlag: 2, message: 'Something went wrong' }
            }
        } else {
            toast.error('Please click preview to identify errors', { autoClose: 2000 })
        }
    }

    const hasKeys = (array, keysToCheck) => {
        // Helper function to get unique keys from the array of objects
        const getUniqueKeys = (array) => {
            const keysSet = new Set();
            array.forEach(obj => {
                Object.keys(obj).forEach(key => keysSet.add(key));
            });
            return Array.from(keysSet);
        };

        // Get the unique keys from the JSON array
        const uniqueKeys = getUniqueKeys(array);

        // Check if all keys in keysToCheck exist in uniqueKeys
        return keysToCheck.every(key => uniqueKeys.includes(key));
    };

    useEffect(() => {
        if (dataFlag) {
            if (importFlag === 1) {
                setShuffle(false)
                data_format_check_tamil && Object.keys(data[0]).length < 17 ? setTamilHeader(true) : setTamilHeader
                    (false);
                if (selectedLanguage?.value === 0) {
                    var data_format_check_tamilandenglish = hasKeys(data, tamil_english_header1);
                    if (!data_format_check_tamilandenglish) {
                        setData([]);
                        setDataFlag(false);
                        toast.error('Please Upload Valid Data', { autoClose: 2000 })
                    }
                }
                if (selectedLanguage?.value === 1) {
                    var data_format_check_english = hasKeys(data, english_header1);
                    if (!data_format_check_english) {
                        setData([]);
                        setDataFlag(false);
                        toast.error('Please Upload Valid Data', { autoClose: 2000 })
                    }
                }
                if (selectedLanguage?.value === 2) {
                    var data_format_check_tamil = hasKeys(data, tamil_header1);
                    if (!data_format_check_tamil) {
                        setData([]);
                        setDataFlag(false);
                        toast.error('Please Upload Valid Data', { autoClose: 2000 })
                    }
                }
            } else {
                if (data[0]?.['Question ID']) {
                    setShuffle(false)
                }
                var data_format_check_tamil = hasKeys(data, tamil_header);
                data_format_check_tamil && Object.keys(data[0]).length < 17 ? setTamilHeader(true) : setTamilHeader(false);
                if (!data_format_check_tamil) {
                    var data_format_check_english = hasKeys(data, english_header);
                    console.log('data_format_check_english', data_format_check_english);
                    if (!data_format_check_english) {
                        setData([]);
                        setDataFlag(false);
                        toast.error('Please Upload Valid Data', { autoClose: 2000 })
                    }
                }
            }
        }
    }, [data])

    console.log('Object.keys(data).length', data.length > 0 ? Object?.keys(data[0])?.length : '');

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Card className="crd-min-ht">
                        <CardBody>
                            {/* <a href="#" className="previous round">&#8249;</a> */}
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                            }}
                            >
                                <Row>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                                            <Select
                                                value={selectedExamPaper}
                                                onChange={(selectedOption) => {
                                                    validation.setFieldValue("selectedExamPaper", selectedOption);
                                                    handleSelectPaper(selectedOption);
                                                }}
                                                options={optionExamPaper?.map((option) => ({
                                                    label: option.exp_name,
                                                    value: option.expid,
                                                }))}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                className={`select2-selection ${validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? 'is-invalid' : ''}`}
                                            />
                                            {validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? (
                                                <div className="invalid-feedback">{validation.errors.selectedExamPaper}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label>Subject<span style={{ color: 'red' }}>*</span></Label>
                                            <Select
                                                value={selectedSubjectName}
                                                onChange={(selectedOption) => {

                                                    validation.setFieldValue("selectedSubjectName", selectedOption);
                                                    handleSelectSubjectName(selectedOption);
                                                }}
                                                options={optionSubjectName?.map((option) => ({
                                                    label: option.subjectname,
                                                    value: option.subjectid,
                                                }))}

                                                // styles={customStyles}
                                                validate={{
                                                    required: { value: true },
                                                }}

                                                className={`select2-selection ${validation.touched.selectedSubjectName && validation.errors.selectedSubjectName ? 'is-invalid' : ''}`}
                                            />
                                            {validation.touched.selectedSubjectName && validation.errors.selectedSubjectName ? (
                                                <div className="invalid-feedback">{validation.errors.selectedSubjectName}</div>
                                            ) : null}

                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label>Subcategory
                                            </Label>
                                            <Select
                                                value={selectedSubjectSubcatName}
                                                onChange={(selectedOption) => {
                                                    handleSelectSubjectSubcatName(selectedOption);
                                                }}
                                                options={optionSubjectSubcatName?.map((option) => ({
                                                    label: option.ssc_name,
                                                    value: option.sscid,
                                                }))}
                                                isDisabled={disabledsubcat}
                                                // styles={customStyles}
                                                validate={{
                                                    required: { value: true },
                                                }}

                                                className={`select2-selection ${validation.touched.selectedSubjectName && validation.errors.selectedSubjectName ? 'is-invalid' : ''}`}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label>Topic
                                            </Label>
                                            <Select
                                                value={selectedTopicName}
                                                onChange={(selectedOption) => {
                                                    validation.setFieldValue("selectedTopicName", selectedOption);
                                                    handleSelectTopicName(selectedOption);
                                                }}
                                                options={optionTopicName?.map((option) => ({
                                                    label: option.topicname,
                                                    value: option.topicid,
                                                }))}
                                                validate={{
                                                    required: { value: true },
                                                }}

                                                className={`select2-selection ${validation.touched.selectedTopicName && validation.errors.selectedTopicName ? 'is-invalid' : ''}`}
                                                isDisabled={disabledtopic}
                                            />
                                        </div>
                                    </Col>
                                    {/* {importFlag !== 1 && <Col lg="1" style={{ marginTop: '2%' }}> */}
                                    {/* <Label>Type</Label> */}
                                    {/* <div className="square-switch" */}
                                    {/* // style={{ marginTop: '17%' }} */}
                                    {/* >
                                            <input
                                                type="checkbox"
                                                id="shuffle"
                                                className="switch switch-info"
                                                checked={shuffle}
                                                onClick={() => setShuffle(!shuffle)}
                                            />
                                            <label htmlFor="shuffle" data-on-label="Save" data-off-label="Update" />
                                        </div> */}
                                    {/* </Col>} */}
                                    <Col lg="2">
                                        <div className="mb-3">
                                            <Label>Exam Level<span style={{ color: 'red' }}>*</span></Label>
                                            <Select
                                                value={selectedExamLevel}
                                                onChange={(selectedOption) => {
                                                    validation.setFieldValue("selectedExamLevel", selectedOption);
                                                    setselectedExamLevel(selectedOption);
                                                }}
                                                options={optionExamLevel?.map((option) => ({
                                                    label: option.levelname,
                                                    value: option.levelid,
                                                }))}
                                                validate={{
                                                    required: { value: true },
                                                }}

                                                className={`select2-selection ${validation.touched.selectedExamLevel && validation.errors.selectedExamLevel ? 'is-invalid' : ''}`}
                                            />
                                            {validation.touched.selectedExamLevel && validation.errors.selectedExamLevel ? (
                                                <div className="invalid-feedback">{validation.errors.selectedExamLevel}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg="2">
                                        <div className="mb-3">
                                            <Label>Language<span style={{ color: 'red' }}>*</span></Label>
                                            <Select
                                                value={selectedLanguage}
                                                onChange={(selectedOption) => {
                                                    validation.setFieldValue("selectedLanguage", selectedOption);
                                                    setselectedLanguage(selectedOption);
                                                }}
                                                options={optionAllLanguages?.map((option) => ({
                                                    label: option.l_name,
                                                    value: option.lid,
                                                }))}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                isDisabled={importFlag === 1 ? false : true}
                                                className={`select2-selection ${validation.touched.selectedLanguage && validation.errors.selectedLanguage ? 'is-invalid' : ''}`}
                                            />
                                            {validation.touched.selectedLanguage && validation.errors.selectedLanguage ? (
                                                <div className="invalid-feedback">{validation.errors.selectedLanguage}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    {importFlag !== 1 && <Col lg="2"
                                        style={{ marginTop: '2%', width: 'unset' }}
                                    >
                                        <Button
                                            type="button"
                                            className="btn btn-warning"
                                            onClick={validation.handleSubmit}
                                        >
                                            Download Template
                                        </Button>
                                    </Col>}
                                    <Col lg="3">
                                        <Label>Upload <span>(jpg,jpeg,png,pdf only)</span><span style={{ color: 'red' }}>*</span></Label>
                                        <Input type="file" id="regfileuplaod" name="regfileuplaod"
                                            onChange={handleFileUpload} />
                                    </Col>
                                    <Col lg="2">
                                        <div className="mb-3" style={{ width: 'auto' }}>
                                            <Label>File Name: </Label><br />
                                            <label style={{ fontWeight: '900', color: 'coral', fontSize: 'larger' }}>{fileName}</label>
                                        </div>
                                    </Col>
                                    <Col lg="1" sm='2' style={{ marginTop: '2%', width: 'unset' }}>
                                        <Button
                                            type="button"
                                            className="btn btn-success"
                                            // style={{ marginTop: '29%' }}
                                            onClick={() => {
                                                setPreviewFlag(true)
                                                previewExcel()
                                            }
                                            }
                                        >
                                            Preview
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>

                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="new-table-container">
                                            <table className="new-table-container-table">
                                                <thead className="new-table-container-thead">
                                                    {
                                                        data.length > 0 && Object.keys(data[0]).length > 17 ?
                                                            <tr>
                                                                <th className="new-table-container-th">S.No.</th>
                                                                <th className="new-table-container-th">Question ID</th>
                                                                <th className="new-table-container-th">Exam Paper</th>
                                                                <th className="new-table-container-th">Subject Subcategory</th>
                                                                <th className="new-table-container-th">Topic</th>
                                                                <th className="new-table-container-th">Exam Level</th>
                                                                <th className="new-table-container-th">Shuffle</th>
                                                                <th className="new-table-container-th">English Question Text</th>
                                                                <th className="new-table-container-th">English Option 1</th>
                                                                <th className="new-table-container-th">English Option 2</th>
                                                                <th className="new-table-container-th">English Option 3</th>
                                                                <th className="new-table-container-th">English Option 4</th>
                                                                <th className="new-table-container-th">English Option 5</th>
                                                                <th className="new-table-container-th">English Correct Option</th>
                                                                <th className="new-table-container-th">Language</th>
                                                                <th className="new-table-container-th">English Notes</th>
                                                                {importFlag === 1 && <><th className="new-table-container-th">English Approve</th>
                                                                    <th className="new-table-container-th">English Reject Reason</th></>}
                                                                <th className="new-table-container-th">Regional Question Text</th>
                                                                <th className="new-table-container-th">Regional Option 1</th>
                                                                <th className="new-table-container-th">Regional Option 2</th>
                                                                <th className="new-table-container-th">Regional Option 3</th>
                                                                <th className="new-table-container-th">Regional Option 4</th>
                                                                <th className="new-table-container-th">Regional Option 5</th>
                                                                <th className="new-table-container-th">Regional Correct Option</th>
                                                                <th className="new-table-container-th">Language</th>
                                                                <th className="new-table-container-th">Regional Notes</th>
                                                                {importFlag === 1 && <><th className="new-table-container-th">Regional Approve</th>
                                                                    <th className="new-table-container-th">Regional Reject Reason</th></>}
                                                            </tr> :

                                                            <tr>
                                                                <th className="new-table-container-th">S.No.</th>
                                                                <th className="new-table-container-th">Question ID</th>
                                                                <th className="new-table-container-th">Exam Paper</th>
                                                                <th className="new-table-container-th">Subject Subcategory</th>
                                                                <th className="new-table-container-th">Topic</th>
                                                                <th className="new-table-container-th">Exam Level</th>
                                                                <th className="new-table-container-th">Shuffle</th>
                                                                <th className="new-table-container-th">Question Text</th>
                                                                <th className="new-table-container-th">Option 1</th>
                                                                <th className="new-table-container-th">Option 2</th>
                                                                <th className="new-table-container-th">Option 3</th>
                                                                <th className="new-table-container-th">Option 4</th>
                                                                <th className="new-table-container-th">Option 5</th>
                                                                <th className="new-table-container-th">Correct Option</th>
                                                                <th className="new-table-container-th">Language</th>
                                                                <th className="new-table-container-th">Notes</th>
                                                                {importFlag === 1 && <><th className="new-table-container-th">Approve</th>
                                                                    <th className="new-table-container-th">Reject Reason</th></>}
                                                            </tr>
                                                    }
                                                </thead>
                                                <tbody>
                                                    {list?.map((question) => {

                                                        // const { "S.No.": _, "Question ID": __, ...rest } = question;
                                                        // const key = JSON.stringify(rest);
                                                        // const isDuplicate = duplicateCounts[key] > 1; // Check for duplicates

                                                        // const isEmptyExamPaper = !question["Exam Paper"]; // Check if Exam Paper is empty
                                                        // const isEmptySubject = !question["Subject Subcategory"]; // Check if Subject Subcategory is empty
                                                        var no = 'NO';
                                                        var correct_ans = false;
                                                        var reject_valid = false;
                                                        var option_check = false;
                                                        var options_valid = false;
                                                        var approve_valid = false;
                                                        var headerText = '';
                                                        headerText = tamilHeader ? "Regional" : "English"


                                                        // // option is empty or not check when question is no empty
                                                        // if (Object.keys(data[0]).length < 17 && question['English Question Text']) {
                                                        //     if (!question['English Option 1'] || !question['English Option 2'] || !question['English Option 3'] || !question['English Option 4'] || !question['English Option 5'] || !question['English Correct Option']) {
                                                        //         option_check = true;
                                                        //     }
                                                        // }

                                                        // // option is empty or not check when question is no empty
                                                        // if (Object.keys(data[0]).length < 17 && question['Tamil Question Text']) {
                                                        //     if (!question['Tamil Option 1'] || !question['Tamil Option 2'] || !question['Tamil Option 3'] || !question['Tamil Option 4'] || !question['Tamil Option 5'] || !question['Tamil Correct Option']) {
                                                        //         option_check = true;
                                                        //     }
                                                        // }

                                                        if (question['English Question Text']) {
                                                            if (!question['English Option 1'] || !question['English Option 2'] || !question['English Option 3'] || !question['English Option 4'] || !question['English Correct Option']) {
                                                                option_check = true;
                                                            }
                                                        }

                                                        if (question['Regional Question Text']) {
                                                            if (!question['Regional Option 1'] || !question['Regional Option 2'] || !question['Regional Option 3'] || !question['Regional Option 4'] || !question['Regional Correct Option']) {
                                                                option_check = true;
                                                            }
                                                        }

                                                        // 5th option and correct question is same check
                                                        if (question['Regional Option 5'] && question['Regional Correct Option']) {
                                                            if (question['Regional Option 5'] === question['Regional Correct Option']) {
                                                                options_valid = true;
                                                            }
                                                        }
                                                        if (question['English Option 5'] && question['English Correct Option']) {
                                                            if (question['English Option 5'] === question['English Correct Option']) {
                                                                options_valid = true;
                                                            }
                                                        }

                                                        // check reject reason is not empty when approve status is no
                                                        reject_valid = typeof question['English Approve'] === 'string' && question['English Approve'].toLocaleLowerCase() === no.toLocaleLowerCase() && !question['English Reject Reason'] || typeof question['Tamil Approve'] === 'string' && question['Tamil Approve'].toLocaleLowerCase() === no.toLocaleLowerCase() && !question['Tamil Reject Reason'];

                                                        // if (importFlag === 1) {
                                                        //     if (!question['English Approve'] || !question['Regional Approve']) {
                                                        //         approve_valid = true;
                                                        //     }
                                                        // }

                                                        console.log('option_check', option_check);
                                                        console.log('options_valid', options_valid);
                                                        console.log('reject_valid', reject_valid);
                                                        console.log('tamilHeader', tamilHeader);


                                                        if (options_valid || reject_valid || option_check || approve_valid) {
                                                            correct_ans = true;


                                                            // const isHighlighted = isEmptyExamPaper || isEmptySubject; // Highlight if either is empty

                                                            // const isRepeated = repeatedQuestionIDs.includes(String(question["Question ID"]));

                                                            if (Object.keys(data[0]).length > 17) {
                                                                return (
                                                                    <tr
                                                                        key={question["S.No."]}
                                                                        style={{
                                                                            backgroundColor: correct_ans ? '#f46a6a' : 'white',
                                                                            color: 'black', // Text color
                                                                            transition: 'background-color 0.3s, color 0.3s', // Add transition for text color
                                                                        }}
                                                                    >
                                                                        <td className="new-table-container-td">{question["S.No."]}</td>
                                                                        <td className="new-table-container-td">{question["Question ID"]}</td>
                                                                        <td className="new-table-container-td">{question["Exam Paper"]}</td>
                                                                        <td className="new-table-container-td">{question["Subject Subcategory"]}</td>
                                                                        <td className="new-table-container-td">{question["Topic"]}</td>
                                                                        <td className="new-table-container-td">{question["Exam Level"]}</td>
                                                                        <td className="new-table-container-td">{question["Shuffle"]}</td>
                                                                        <td className="new-table-container-td">{question["English Question Text"]}</td>
                                                                        <td className="new-table-container-td">{question["English Option 1"]}</td>
                                                                        <td className="new-table-container-td">{question["English Option 2"]}</td>
                                                                        <td className="new-table-container-td">{question["English Option 3"]}</td>
                                                                        <td className="new-table-container-td">{question["English Option 4"]}</td>
                                                                        <td className="new-table-container-td">{question["English Option 5"]}</td>
                                                                        <td className="new-table-container-td">{question["English Correct Option"]}</td>
                                                                        <td className="new-table-container-td">{question["Language"]}</td>
                                                                        <td className="new-table-container-td">{question["English Notes"]}</td>
                                                                        {
                                                                            importFlag === 1 && <><td className="new-table-container-td">{question["English Approve"]}</td>
                                                                                <td className="new-table-container-td">{question["English Reject Reason"]}</td></>
                                                                        }
                                                                        <td className="new-table-container-td">{question["Regional Question Text"]}</td>
                                                                        <td className="new-table-container-td">{question["Regional Option 1"]}</td>
                                                                        <td className="new-table-container-td">{question["Regional Option 2"]}</td>
                                                                        <td className="new-table-container-td">{question["Regional Option 3"]}</td>
                                                                        <td className="new-table-container-td">{question["Regional Option 4"]}</td>
                                                                        <td className="new-table-container-td">{question["Regional Option 5"]}</td>
                                                                        <td className="new-table-container-td">{question["Regional Correct Option"]}</td>
                                                                        <td className="new-table-container-td">{question["Language"]}</td>
                                                                        <td className="new-table-container-td">{question["Regional Notes"]}</td>
                                                                        {
                                                                            importFlag === 1 && <><td className="new-table-container-td">{question["Regional Approve"]}</td>
                                                                                <td className="new-table-container-td">{question["Regional Reject Reason"]}</td></>
                                                                        }
                                                                    </tr>
                                                                );
                                                            } else {
                                                                return (
                                                                    <tr
                                                                        key={question["S.No."]}
                                                                        style={{
                                                                            backgroundColor: correct_ans ? '#f46a6a' : 'white',
                                                                            color: 'black', // Text color
                                                                            transition: 'background-color 0.3s, color 0.3s', // Add transition for text color
                                                                        }}
                                                                    >
                                                                        <td className="new-table-container-td">{question["S.No."]}</td>
                                                                        <td className="new-table-container-td">{question["Question ID"]}</td>
                                                                        <td className="new-table-container-td">{question["Exam Paper"]}</td>
                                                                        <td className="new-table-container-td">{question["Subject Subcategory"]}</td>
                                                                        <td className="new-table-container-td">{question["Topic"]}</td>
                                                                        <td className="new-table-container-td">{question["Exam Level"]}</td>
                                                                        <td className="new-table-container-td">{question["Shuffle"]}</td>
                                                                        <td className="new-table-container-td">{question["" + headerText + " Question Text"]}</td>
                                                                        <td className="new-table-container-td">{question["" + headerText + " Option 1"]}</td>
                                                                        <td className="new-table-container-td">{question["" + headerText + " Option 2"]}</td>
                                                                        <td className="new-table-container-td">{question["" + headerText + " Option 3"]}</td>
                                                                        <td className="new-table-container-td">{question["" + headerText + " Option 4"]}</td>
                                                                        <td className="new-table-container-td">{question["" + headerText + " Option 5"]}</td>
                                                                        <td className="new-table-container-td">{question["" + headerText + " Correct Option"]}</td>
                                                                        <td className="new-table-container-td">{question["" + headerText + " Notes"]}</td>
                                                                        {
                                                                            importFlag === 1 && <><td className="new-table-container-td">{question["" + headerText + " Approve"]}</td>
                                                                                <td className="new-table-container-td">{question["" + headerText + " Reject Reason"]}</td></>
                                                                        }
                                                                    </tr>
                                                                );
                                                            }
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <Row className="mt-3 text-center col-lg-12">
                                            {/* <Col className="col-lg-2 text-center">
                                                <Label style={{ marginTop: '2px', fontSize: 'larger', color: '#f46a6a' }}>Duplicate Records : {duplicateRecord}</Label>
                                            </Col> */}
                                            <Col className="col-lg-2 text-center">
                                                <Label style={{ marginTop: '2px', fontSize: 'larger', color: '#f46a6a' }}>Error Records : {errorRecords}</Label>
                                            </Col>
                                            <Col className="col-lg-2 text-center">
                                                <Label className='text-success' style={{ marginTop: '2px', fontSize: 'larger' }}>No.of.Questions : {noOfQuestions}</Label>
                                            </Col>
                                            {/* <Col className="col-lg-2 text-center">
                                                <Label className='text-secondary' style={{ marginTop: '2px', fontSize: 'larger' }}>Total Records : {totalRecords}</Label>
                                            </Col> */}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </CardBody>
                        <CardFooter>
                            <Row className="mb-3">
                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                    <button type="button me-2" style={{ marginLeft: "10px" }}
                                        onClick={() => importFlag === 1 ? approveQuestionsExcel() : saveQuestionsExcel()}
                                        className="btn btn-success">{shuffle ? 'Save' : 'Update'}</button>
                                    <button type="button" style={{ marginLeft: "10px" }}
                                        onClick={() => importFlag === 1 ? navigate('/list-question-approval') : navigate('/questions')}
                                        className="btn btn-danger">Close</button>
                                </div>
                            </Row>
                        </CardFooter>
                    </Card>
                </Container>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment>
    )

}

export default ImportExcel;