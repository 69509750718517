// Created by: Aruna Devi
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PaginationTableContainer from '../../components/Common/PaginationTableContainer';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinners from "../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../components/Common/DeleteModal";
import Select from "react-select";
import * as url from "../../../src/helpers/url_helper";
import { getmasterlist } from "../../store/actions";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import * as constants from "../../constants/layout"
//Import Flatepicker
import "./studymaterials.scss"
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Container, Form, Label, Card, CardBody, Badge } from "reactstrap";

const StudyMaterials = () => {
  document.title = "Study Materials";

  const store = useSelector(state => state.Studymaterial)
  // const store = useSelector(state => state.ExamBoardCategory)
  const [checkedData, setcheckedData] = useState([]);

  const masterstore = useSelector(state => state.ExamBoardCategory)
  const dispatch = useDispatch();
  const user = getLoggedInUser()
  const [board, setBoard] = useState();
  const [studymaterialData, setstudymaterialData] = useState()
  const [submitbtn, setSubmitbtn] = useState(false)
  const [deleteTrigger, setDeleteTrigger] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [studymaterial, setstudymaterial] = useState([]);
  const navigate = useNavigate();

  const [canView, setCanView] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [canModify, setCanModify] = useState(false)
  const [canRemove, setCanRemove] = useState(false)
  const [optionBoardName, setOptionBoardName] = useState([]);
  const [optionSubjectName, setOptionSubjectName] = useState([]);
  const [optionAllSubjectName, setOptionAllSubjectName] = useState([]);
  const [optionExamPaper, setOptionExamPaper] = useState([]);
  const [optionAllExamPaper, setOptionAllExamPaper] = useState([]);
  const [optionAllLanguage, setOptionAllLanguage] = useState([]);
  const [optionLanguage, setOptionLanguage] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPageData = 8;
  const [approveStatus, setApproveStatus] = useState([]);
  const indexOfLast = currentPage * perPageData;
  const [optionTopicName, setOptionTopicName] = useState([]);
  const [optionAllTopicName, setOptionAllTopicName] = useState([]);
  const [optionStatus, setOptionStatus] = useState([]);
  const [selectedBoardName, setselectedBoardName] = useState(null);
  const [selectedTopicName, setselectedTopicName] = useState(null);
  const [selectedSubjectName, setselectedSubjectName] = useState(null);
  const [selectedExamPaper, setselectedExamPaper] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [offset, setOffset] = useState(0);
  const [generalsetting, setGeneralSetting] = useState([]);
  const [userBasedApproveList, setUserBasedApproveList] = useState([]);
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState({
    label: "All",
    value: 0
  });
  const [current, setCurrent] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const [optionSubCategory, setOptionSubCategory] = useState([]);
  const [optionSubCategoryAll, setOptionSubCategoryAll] = useState([]);

  const language = [{ l_name: 'Tamil', value: 1, lid: 4 }, { l_name: 'English', value: 1, lid: 8 }]

  const [selectedStatus, setselectedStatus] = useState(null);
  const [selectedApprove, setSelectedApprove] = useState(null);
  const [filterData, setFilterData] = useState({
    selectedBoardName: 0,
    selectedSubjectName: 0 - 0,
    selectedSubCategoryName: 0,
    selectedTopicName: 0,
    selectedStatus: 0,
    selectedApprove: 5,
    selectedExamPaper: 0,
    selectedLanguage: 0
  });
  useEffect(() => {
    if (masterstore.masterlist != undefined) {


      if (masterstore?.masterlist && masterstore?.masterlist?.generalsettings) {
        setGeneralSetting(masterstore?.masterlist?.generalsettings)
      }
      //var filtered_approve_lang;
      if (masterstore.masterlist && masterstore.masterlist?.userBasedApproveList) {
        const data = masterstore.masterlist?.userBasedApproveList
        var filtered_approve_lang = data.filter(e => e.uid == user?.uid)
        // filtered_approve_lang = filtered_approve[0]?.u_langflag[0].split(",");

        // if (filtered_approve && filtered_approve.length > 0) {
        //   if (filtered_approve[0] && filtered_approve[0]?.u_langflag && filtered_approve[0]?.u_langflag !== null && filtered_approve[0]?.u_langflag !== "" && filtered_approve[0]?.u_langflag !== undefined) {

        //  if(filtered_approve[0]?.u_langflag != null && filtered_approve[0]?.u_langflag != undefined)
        //       filtered_approve_lang = filtered_approve[0]?.u_langflag?.split(",");
           
        //     setUserBasedApproveList(filtered_approve)
        //   }
        // } else {
        //   setUserBasedApproveList(data)
        // }
        // setUserBasedApproveList(filtered_approve)
      }
      if (masterstore?.masterlist && masterstore?.masterlist?.languageList) {
       
        if (filtered_approve_lang && filtered_approve_lang.length > 1) {

          setOptionLanguage([{ l_name: "All", lid: "0" }, ...masterstore.masterlist?.languageList])
          setOptionAllLanguage([{ l_name: "All", lid: "0" }, ...masterstore.masterlist?.languageList])
        } else {
          if (filtered_approve_lang?.includes('1')) {
            var data = masterstore.masterlist?.languageList.filter(e => e.lid == 1)
            setOptionAllLanguage(data)
            setOptionLanguage(data)
          } else if (filtered_approve_lang?.includes('2')) {
            var data = masterstore.masterlist?.languageList.filter(e => e.lid == 2)
            setOptionAllLanguage(data)
            setOptionLanguage(data)
          } else {
            setOptionAllLanguage(masterstore.masterlist?.languageList)
            setOptionLanguage(masterstore.masterlist?.languageList)
          }
        }


        // setOptionLanguage([{ l_name: "All", lid: "0" }, ...masterstore.masterlist.languageList])
        // setOptionAllLanguage([{ l_name: "All", lid: "0" }, ...masterstore.masterlist.languageList])
      }
      if (masterstore?.masterlist && masterstore?.masterlist?.verificationList) {
        setApproveStatus([{ statusname: "All", statusid: "-1" }, ...masterstore.masterlist.verificationList])
        // setOptionAllLanguage([{ l_name: "All", lid: "0" }, ...masterstore.masterlist.languageList])
      }

      if (masterstore?.masterlist && masterstore?.masterlist?.subCategryList) {
        setOptionSubCategory([{ ssc_name: "All", sscid: "0" }])
        setOptionSubCategoryAll([{ ssc_name: "All", sscid: "0" }, ...masterstore?.masterlist?.subCategryList])
      }

    }

  }, [masterstore])

  useEffect(() => {
    document.title = "Study Materials | TNPSC";


    document.getElementById('headerTitle').innerHTML = 'Study Materials';

    setselectedBoardName({
      label: "All",
      value: 0
    })
    setselectedTopicName({
      label: "All",
      value: 0
    })
    setselectedSubjectName({
      label: "All",
      value: 0
    })
    setSelectedLanguage({
      label: "All",
      value: 0
    })
    setselectedStatus({
      label: "All",
      value: 0
    })
    setselectedExamPaper({
      label: "All",
      value: 0
    })
    setSelectedApprove({
      label: "Rejected",
      value: 5
    })

  }, [dispatch]);

  useEffect(() => {

    var page_indexOfLast = currentPage * perPageData;
    var offset = page_indexOfLast - perPageData;
    GetstudymaterialList(offset);

  }, [filterData, currentPage]);
  useEffect(() => {
    if (current) {
      GetstudymaterialList(current)
    }
    setCurrent(0)
  }, [current])

  const handleCheckboxChange = (stid, data) => {
    console.log('--------', stid)
    console.log('--------', data)
    setcheckedData((prevSelected) => {
      if (prevSelected.includes(data)) {
        return prevSelected.filter((id) => id !== data);
      } else {
        return [...prevSelected, data];
      }
    })

    setSelectedRows((prevSelected) => {
      if (prevSelected.includes(stid)) {
        return prevSelected.filter((id) => id !== stid);
      } else {
        return [...prevSelected, stid];
      }
    });
  };

  var studymaterialListData;

  async function GetstudymaterialList(offset) {

    const user = getLoggedInUser()

    if (filterData.selectedSubjectName != 0) {
      var st_sid = filterData.selectedSubjectName
    } else var st_sid = 0

    const filter_user_id = user?.user_previleges.find(f =>
      Number(f.urp_mid) === constants.mainMenuList.StudyMaterial && f.urp_type === constants.menuTypes.Permission1);

    const data = {
      "user_id": Number(user.uid),
      "st_exctid": 0,
      "st_expid": Number(filterData.selectedExamPaper),
      "st_sid": Number(st_sid),
      "stc_langflag": Number(filterData?.selectedLanguage),
      "st_sscid": Number(selectedSubCategoryName?.value),
      "st_tid": 0,
      "st_vstatus": 0,
      "st_stsid": Number(filterData.selectedStatus),
      "limit": 10,
      "offset": Number(current) != 0 ? Number(current - 1) : 0,
      "filter_user_id": filter_user_id?.uid ? 0 : Number(user?.uid),
      "st_approval_flag": 0,
      "st_approval_status":Number(filterData.selectedApprove),
    }
    console.log('da6a', data)
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(url.BASE_URL + 'master/getstudymaterialList', data, config)
    if (response1 && response1.statusFlag === 3) { // user validation
      toast.error(response1.message, { autoClose: 2000 });
      localStorage.removeItem("authUser");
      sessionStorage.removeItem("SESSION_KEY")
      window.location.reload()
      return
    }
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === constants.invalidRefreshToken) {
          logoutUser()
        } else {
          GetstudymaterialList(offset) // calling same function again getting refresh token
        }
      }
    }
    if (response1 && response1.data && response1.data.body) {
      setstudymaterialData(response1.data.body.studymaterialList)
      studymaterialListData = response1.data.body.studymaterialList;

      setTotalCount(response1.data.body.studymaterialCount)
      return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
    } else {
      return { statusFlag: 2, message: 'Something went wrong' }
    }

  }

  const handleSelectAll = (checked) => {
    // Check if qaListdata is defined and is an array
    if (Array.isArray(studymaterialListData)) {
      if (checked) {
        const allstids = studymaterialListData.filter(item => {
          const langnameArray = item.langname?.split(',').map(value => value.trim());
          const aggregatedValuesArray = item.aggregated_values?.split(',').map(value => value.trim());

          const hasEnglish = langnameArray.includes('English');
          const hasRegional = langnameArray.some(value => value !== 'English');

          // Adjust conditions based on your requirements
          return (
            langnameArray.length === aggregatedValuesArray.length && (
              (hasEnglish && aggregatedValuesArray.includes('English')) ||
              (hasRegional && aggregatedValuesArray.includes('Regional')) ||
              (hasEnglish && hasRegional)
            )
          );
        }).map(item => item.stid); // Get all visible stids

        console.log('Selecting visible stids:', allstids);
        setSelectedRows(allstids); // Select only the visible stids
      } else {
        console.log('Deselecting all');
        setSelectedRows([]); // Deselect all
      }
    } else {
      console.error('studymaterialListdata is undefined or not an array');
    }
  };




  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        // accessorKey: 'action',
        // enableColumnFilter: false,
        // enableSorting: true,

        width: '8%',
        // width: (cell) => cell.value.length * 10, 
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {/* {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.StudyMaterial && f.urp_type === constants.menuTypes.Create) && (
                  <Button
                    to="#"
                    className="btn btn-sm btn-soft-warning"
                    onClick={() => {
                      const blueprintData = cellProps.row.original;

                      localStorage.setItem('isMaterialClone', '1')
                      navigate(
                        `/add-studymaterial/${cellProps.row.original.stid}`
                      )
                    }}
                  >
                    <i className="mdi mdi-content-duplicate" id="clonetooltip" />
                    <UncontrolledTooltip placement="top" target="clonetooltip">
                      Clone
                    </UncontrolledTooltip>
                  </Button>)} */}
              {generalsetting && user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.StudyMaterial
                &&
                f.urp_type === constants.menuTypes.Modify
                || f.urp_type === constants.menuTypes.Special
              ) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const blueprintData = cellProps.row.original;
                      localStorage.setItem('isMaterialClone', '0')
                      navigate(`/add-studymaterial/${cellProps.row.original.stid}`);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>
                )}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.StudyMaterial && f.urp_type === constants.menuTypes.Remove) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      const studymaterialData = cellProps.row.original; onClickDelete(studymaterialData);
                    }}>
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>)}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'pl-25',
        cell: (cellProps) => {
          var data = cellProps.row.original.rno
          return (
            <div className="d-flex align-items-center">
              <span>{data}</span>
            </div>
          );


        }
      },
      // {
      //   header: <input
      //     type="checkbox"
      //     onChange={(e) => handleSelectAll(e.target.checked)}
      //     checked={selectedRows?.length === studymaterialListData && studymaterialListData?.length > 0}
      //   />,
      //   accessorKey: 'select',
      //   cell: ({ row }) => {
      //     const stid = row.original.stid;
      //     const langname = row.original.langname;
      //     const aggregated_values = row.original.aggregated_values;

      //     console.log('langname', langname);
      //     console.log('aggregated_values', aggregated_values);

      //     // Convert comma-separated strings into arrays
      //     const langnameArray = langname?.split(',').map(value => value.trim());
      //     const aggregatedValuesArray = aggregated_values?.split(',').map(value => value.trim());

      //     console.log('lllllllll', langnameArray)
      //     console.log('lllllllll', aggregatedValuesArray)

      //     // Find common values
      //     const commonValues = langnameArray?.filter(value => aggregatedValuesArray?.includes(value));

      //     // Find different values
      //     // const differentInLangname = langnameArray.filter(value => !aggregatedValuesArray.includes(value));
      //     const differentValues = aggregatedValuesArray?.filter(value => !langnameArray?.includes(value));

      //     console.log('diff', differentValues)
      //     console.log('diff', commonValues)
      //     const hasEnglish = langnameArray.includes('English');
      //     const hasRegional = langnameArray.some(value => value !== 'English'); // Check for any non-English language

      //     return (
      //       // <input
      //       //   type="checkbox"
      //       //   checked={selectedRows.includes(stid)} // Check if stid is in selectedRows
      //       //   onClick={() => handleCheckboxChange(stid, row.original)} // Handle the checkbox change

      //       // />

      //       <div className="d-flex align-items-center">

      //         {


      //           langnameArray && aggregatedValuesArray &&
      //             langnameArray.length === aggregatedValuesArray.length &&
      //             langnameArray.includes('English') &&
      //             aggregatedValuesArray.includes('English') ? (
      //             hasEnglish
      //               ? (
      //                 <input
      //                   type="checkbox"
      //                   checked={selectedRows.includes(stid)} // Check if stid is in selectedRows
      //                   onClick={() => handleCheckboxChange(stid, row.original)} // Handle the checkbox change
      //                 />
      //               ) : null
      //           ) :
      //             langnameArray && aggregatedValuesArray &&
      //               langnameArray.length === aggregatedValuesArray.length &&
      //               langnameArray.includes('Regional') &&
      //               aggregatedValuesArray.includes('Regional') ? (
      //               hasRegional
      //                 ? (
      //                   <input
      //                     type="checkbox"
      //                     checked={selectedRows.includes(stid)} // Check if stid is in selectedRows
      //                     onClick={() => handleCheckboxChange(stid, row.original)} // Handle the checkbox change
      //                   />
      //                 ) : null
      //             ) :
      //               (
      //                 langnameArray && aggregatedValuesArray && langnameArray.length === aggregatedValuesArray.length ? (
      //                   hasEnglish && hasRegional
      //                     ? (
      //                       <input
      //                         type="checkbox"
      //                         checked={selectedRows.includes(stid)} // Check if stid is in selectedRows
      //                         onClick={() => handleCheckboxChange(stid, row.original)} // Handle the checkbox change
      //                       />
      //                     ) : null
      //                 ) : null
      //               )
      //         }



      //         {/* {hasEnglish && hasRegional && (
      //           <Badge color="warning" className="ms-2">
      //             Warning: English and Regional Language Present
      //           </Badge>
      //         )}
      //         {commonValues?.map(value => (
      //           <Badge color="success" className="me-2" key={value}>
      //             {value}
      //           </Badge>
      //         ))}
      //         {differentValues?.map(value => (
      //           <Badge color="danger" className="me-2" key={value}>
      //             {value}
      //           </Badge>
      //         ))} */}
      //       </div>
      //     );
      //   },
      //   enableColumnFilter: false,
      //   width: '5%',
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Title ▼
          </span>
        ),

        accessorKey: 'st_title',
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //       Topic ▼
      //     </span>
      //   ),

      //   accessorKey: 't_name',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam paper ▼
          </span>
        ),

        accessorKey: 'exp_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Subject ▼
          </span>
        ),

        accessorKey: 's_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
            <span style={{ cursor: 'pointer' }}>
                Languages ▼
            </span>
        ),

        accessorKey: 'langname',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
            const langname = cellProps.row.original.langname;
            const aggregated_values = cellProps.row.original.aggregated_values;


            // Convert comma-separated strings into arrays
            const langnameArray = langname?.split(',').map(value => value.trim());
            const aggregatedValuesArray = aggregated_values?.split(',').map(value => value.trim());

            // Find common values
            const commonValues = langnameArray?.filter(value => aggregatedValuesArray?.includes(value));

            // Find different values
            // const differentInLangname = langnameArray.filter(value => !aggregatedValuesArray.includes(value));
            const differentValues = aggregatedValuesArray?.filter(value => !langnameArray?.includes(value));


            if (langname !== null) {

                return (
                    <div className="d-flex align-items-center" >
                        {
                            commonValues?.map(value => {
                                return (
                                    <Badge color="success" className="me-2" key={value}>
                                        {value}
                                    </Badge>
                                )
                            })
                        }
                        {
                            differentValues?.map(value => {
                                return (
                                    <Badge color="danger" className="me-2" key={value}>
                                        {value}
                                    </Badge>
                                )
                            })
                        }
                    </div >
                );
            }
            else {
                return (
                    <div className="d-flex align-items-center" >
                        {
                            aggregatedValuesArray?.map(value => {
                                return (
                                    <Badge color="danger" className="me-2" key={value}>
                                        {value}
                                    </Badge>
                                )
                            })
                        }
                    </div>
                )
            }
        },
    },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Status
          </span>
        ),

        accessorKey: 'l_name',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const aggregated_values = cellProps.row.original.aggregated_values;
          const status_name = cellProps.row.original.status_name;
          const lang_name = cellProps.row.original.langname;

          // const langnameArray = langname?.split(',').map(value => value.trim());
          const aggregatedValuesArray = aggregated_values?.split(',').map(value => value.trim());
          const statusNameArray = status_name?.split(',').map(value => value.trim());
          const langnameArray = lang_name?.split(',').map(value => value.trim());

          // const commonValues = langnameArray?.filter(value => aggregatedValuesArray?.includes(value));

          // const differentValues = aggregatedValuesArray?.filter(value => !langnameArray?.includes(value));

          if (aggregatedValuesArray.length == 1) {

            return (
              <div className="d-flex align-items-center" >
               {statusNameArray[0] == "Rejected" ?
                      <Badge color="danger" className="me-2" key={statusNameArray[0]}>
                        {statusNameArray[0]}
                      </Badge>:
                      <Badge color="success" className="me-2" key={statusNameArray[0]}>
                        {statusNameArray[0]}
                      </Badge>}
                  
              </div >
            );
          }
          else {
        
            if(aggregatedValuesArray.length  == langnameArray.length)
            {
              return (
                <div className="d-flex align-items-center" >
                  {
                    statusNameArray?.map(value => {
                    
                      return (  
                                              
                        <Badge  color={value == "Rejected" ? "danger" : "success"}  className="me-2" key={value}>
                          {value}
                        </Badge>
                      )
                    })
                  }
                </div>
              )
            }
            else
            {
              return (
                <div className="d-flex align-items-center" >
                 
                       {langnameArray.includes("English") ?<Badge color={statusNameArray[0] == "Rejected" ? "danger" : "success"} className="me-2" key={statusNameArray[0]}>
                          {statusNameArray[0]}
                        </Badge>:<Badge color="success" className="me-2" key={"Pending"}>
                          {"Pending"}
                        </Badge>}
                        
                       {langnameArray.includes("Regional") ?<Badge color={statusNameArray[0] == "Rejected" ? "danger" : "success"} className="me-2" key={statusNameArray[0]}>
                          {statusNameArray[0]}
                        </Badge>:<Badge color="success" className="me-2" key={"Pending"}>
                          {"Pending"}
                        </Badge>}
                     
                </div>
              )
            }
         
            
      
          }
        },
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Created By ▼
          </span>
        ),

        accessorKey: 'created_at',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Status',
        accessorKey: 'st_stsid',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const c_stsid = cellProps.row.original.st_vstatus;

          return (
            <div className="d-flex align-items-center">
              {c_stsid == "1" ? (
                <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
              ) : c_stsid == "2" ? (
                <i className="bx bx-x
 text-danger me-2" style={{ fontSize: '20px' }} />
              ) : c_stsid == "3" ? (
                <i className="mdi mdi-book-clock-outline text-warning me-2" style={{ fontSize: '25px' }} />
              ) :
                c_stsid == "4" ? (
                  <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />

                ) :


                  c_stsid == "5" ? (
                    <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
                  ) :



                    ""}
            </div>
          );
        },
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Updated By ▼
          </span>
        ),

        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid ? (
                <span>{mk_stsid}</span>
              ) : (
                <span>-</span>
              )}
            </div>
          );
        },
      }

    ],
    [studymaterialListData, selectedRows]
  );




  useEffect(() => {
    if (masterstore.masterlist && masterstore.masterlist.board_categoryList)
      setOptionBoardName([{ categoryname: "All", exctid: "0" }, ...masterstore.masterlist.board_categoryList])
    if (masterstore.masterlist && masterstore.masterlist.subjectList) {
      setOptionSubjectName([{ subjectname: "All", subjectid: "0" }])
      setOptionAllSubjectName([{ subjectname: "All", subjectid: "0" }, ...masterstore.masterlist.subjectList])
    }

    if (masterstore.masterlist && masterstore.masterlist.topicList) {
      setOptionTopicName([{ topicname: "All", topicid: "0" }])
      setOptionAllTopicName([{ topicname: "All", topicid: "0" }, ...masterstore.masterlist.topicList])
    }

    if (masterstore.masterlist && masterstore.masterlist.examPaperList) {
      setOptionAllExamPaper([{ exp_name: "All", expid: "0" }, ...masterstore.masterlist.examPaperList])
      setOptionExamPaper([{ exp_name: "All", expid: "0" }, ...masterstore.masterlist.examPaperList])

    }

    if (masterstore.masterlist && masterstore.masterlist.statusList)
      setOptionStatus([{ statusname: "All", statusid: "0" }, ...masterstore.masterlist.statusList])






  }, [masterstore.masterlist])

  const customStyles = {
    option: (styles, state, data) => { // fixed
      return {
        ...styles,
        backgroundColor: state.isDisabled
          ? undefined
          : state.isSelected
            ? '#a6b0cf57'
            : state.isFocused
              ? '#556ee6'
              : 'white',

        color: state.isDisabled
          ? '#ccc'
          : state.isSelected
            ? 'black'
            : state.isFocused
              ? 'white'
              : 'black',

        cursor: state.isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !state.isDisabled
            ? state.isSelected
              ? '#556ee6'
              : '#556ee6'
            : undefined,
        },
        fontWeight: state.value && Number(state?.value?.split('-')[1]) !== 0 ? '' : '600'
      };
    }
  };
  function handleSelectBoardName(selectedBoardName) {
    setselectedBoardName(selectedBoardName);

  }
  const handleSelectPaper = (varselectedPaper) => {
    setselectedExamPaper(varselectedPaper)
    setSelectedSubCategoryName({
      label: "All",
      value: 0
    })
    // setOptionSubCategory(optionSubCategoryAll)

    if (varselectedPaper?.value == '0' || varselectedPaper == null || varselectedPaper == undefined) { // if select all option for board, need to checko only exam paper 
      setOptionTopicName(optionAllTopicName)
      setOptionSubjectName(optionAllSubjectName)
    }
    else {
      filter_exampaper_subject(varselectedPaper)
      filter_exampaper_topic(varselectedPaper)
    }


    setselectedTopicName({
      label: "All",
      value: 0
    })
    setselectedSubjectName({
      label: "All",
      value: 0
    })
    setSelectedLanguage({
      label: "All",
      value: 0
    })




  };
  const handleSelectLanguage = (varselectedPaper) => {
    setSelectedLanguage(varselectedPaper)
    // if (varselectedPaper?.value == '0' || varselectedPaper == null || varselectedPaper == undefined) { // if select all option for board, need to checko only exam paper 
    //   // setOptionTopicName(optionAllTopicName)
    //   // setOptionSubjectName(optionAllSubjectName)
    // }
    // else {
    //   filter_exampaper_subject(varselectedPaper)
    //   filter_exampaper_topic(varselectedPaper)
    // }


    // setselectedTopicName({
    //   label: "All",
    //   value: 0
    // })
    // setselectedSubjectName({
    //   label: "All",
    //   value: 0
    // })




  };

  const handleSaveClick = async () => {
    console.log('==============', checkedData)
    console.log('se', selectedRows)
    if (selectedRows?.length > 0) {
      var data = {
        stid: selectedRows,
        user_id: Number(user.uid),
        st_approval_flag: 1
      }
      const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
      const response1 = await axios.post(url.BASE_URL + 'master/saveSMlistScreen', data, config)
      if (response1 && response1.statusFlag === 3) { // user validation
        toast.error(response1.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        sessionStorage.removeItem("SESSION_KEY")
        window.location.reload()
        return
      }
      if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
        const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser()
          } else {
            handleSaveClick() // calling same function again getting refresh token
          }
        }
      }


      if (response1 && response1.data && response1.data.body) {

        toast.success(response1.data.body.message, { autoClose: 2000 });
        GetstudymaterialList()
        setSelectedRows([])

      } else {
        toast.error(response1.data.message, { autoClose: 2000 });

      }

    } else {
      toast.error('Please select at least one checkbox', { autoClose: 2000 });
    }



  };
  function filter_exampaper_subject(varselectedPaper) {
    var filtered_subject_option = optionAllSubjectName.map(e => {
      if (e.expid == varselectedPaper?.value) {
        return e;
      }

    })
    filtered_subject_option = filtered_subject_option.filter(e => e != undefined);
    setOptionSubjectName([{ subjectname: "All", subjectid: "0" }, ...filtered_subject_option])

  }
  function filter_exampaper_topic(varselectedPaper) {
    var filtered_topic_option = optionAllTopicName.map(e => {
      if (e.t_expid == varselectedPaper?.value) {
        return e;
      }

    })
    filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
    setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filtered_topic_option])

  }

  function handleSelectTopicName(selectedTopicName) {
    setselectedTopicName(selectedTopicName);
  }

  function handleSelectSubjectName(varselectedSubjectName) {
    setSelectedSubCategoryName({ label: "All", value: "0" })


    if (varselectedSubjectName?.label != 'All' && varselectedSubjectName != null && varselectedSubjectName != undefined) { // if select all option for board, need to checko only exam paper 
      // handleSelectPaper(selectedExamPaper)
      var filter_subcat = optionSubCategoryAll.filter(e => e.ssc_sid == varselectedSubjectName?.value)
      setOptionSubCategory(filter_subcat)
      const [sid, sscid] = varselectedSubjectName?.value?.split('-');
      var filtered_topic_option = optionAllTopicName.filter(e => e.subjectid == sid && e.subcategoryid == sscid);
      filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
      setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filtered_topic_option])


    }
    else {
      handleSelectPaper(selectedExamPaper)
      var filter_subcat = optionSubCategoryAll.filter(e => Number(e.ssc_sid) == varselectedSubjectName?.subjectid)
      setOptionSubCategory([{ ssc_name: "All", sscid: "0" }, ...filter_subcat])
    }

    setselectedSubjectName(varselectedSubjectName);

    setselectedTopicName({
      label: "All",
      value: 0
    })


  }
  function handleSelectSubCategoryName(varselectedSubjectName) {
    setSelectedSubCategoryName(varselectedSubjectName)
    // if (varselectedSubjectName?.value === '0') {
    //   setOptionTopicName(optionAllTopicName)
    //   setselectedTopicName({ label: "All", value: "0" })
    // } else {
    //   var filter_subcat = optionAllTopicName.filter(e => Number(e.subcategoryid) == varselectedSubjectName?.sscid)
    //   setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filter_subcat])
    // }
  }
  function handleSelectStatus(varselectedStatus) {
    setselectedStatus(varselectedStatus);
  }
  function handleSelectApproveStatus(varselectedStatus) {
    setSelectedApprove(varselectedStatus);
  }

  const getmasterlistapi = () => {
    // setLoading(true)
    dispatch(getmasterlist({ user_id: Number(user.uid) }));
  }

  const onClickDelete = (studymaterialData) => {

    setBoard(studymaterialData.stid);
    setDeleteModal(true);
  };

  const handleDeleteOrder = async () => {
    setLoading(true);

    const data = {
      user_id: Number(user.uid),
      stid: Number(board)
    };

    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/deletestudymaterial', data, config);
      if (response && response.statusFlag === 3) {
        toast.error(response.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        sessionStorage.removeItem("SESSION_KEY")
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await handleDeleteOrder();
          }
        }
      }

      if (response?.data?.body?.statusFlag === 1) {
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error(error);
    } finally {
      setLoading(false);
      setDeleteModal(false);


      await GetstudymaterialList(0);
    }
  };


  useEffect(() => {
    setFilterData({
      selectedBoardName: 0,
      selectedTopicName: 0,
      selectedSubjectName: 0 - 0,
      selectedSubCategoryName: 0,
      selectedStatus: 0,
      selectedApprove: 5,
      selectedExamPaper: 0,
      selectedLanguage: 0

    })
    getmasterlistapi()
    localStorage.removeItem('selectedSubjectName')
    localStorage.removeItem('selectedExamPaper')
    localStorage.removeItem('selectedSubCategoryName')
    localStorage.removeItem('selectedTopicName')
    localStorage.removeItem('applyButton')
    localStorage.removeItem('selectedStatus')
    localStorage.removeItem('selectedLanguage')

  }, [dispatch]);
  //meta title
  //document.title = "Masters | Study Materials";

  //USer role credentials
  useEffect(() => {
    if (user && user.user_previleges) {
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.StudyMaterial)
      if (page_menu_list) {
        setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
        setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
        setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  const exportToExcel = async () => {

    // const workbook = new ExcelJS.Workbook()
    // const worksheet = workbook.addWorksheet('Q&A List')

    // // Add headers with styling
    // const headerRow = worksheet.addRow([
    //   'S.No.',
    //   'Question Text',
    //   'Exam Level',
    //   'Languages',
    //   'Created By',
    //   'Status',
    //   'Updated By'
    // ])

    // headerRow.font = { bold: true }
    // headerRow.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'c0d5e8' } // Background color
    // }

    // // Add data
    // qaList.forEach((item, index) => {
    //   worksheet.addRow([
    //     index + 1,
    //     item.question,
    //     item.exl_name,
    //     item.l_name,
    //     item.created,
    //     item.status,
    //     item.updated
    //   ])
    // })

    // // Save the workbook
    // const buffer = await workbook.xlsx.writeBuffer()
    // const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    // const url = URL.createObjectURL(blob)
    // const a = document.createElement('a')
    // a.href = url
    // a.download = `Q&A List.xlsx`
    // document.body.appendChild(a)
    // a.click()
    // document.body.removeChild(a)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumbs */}

          <Row>
            <Col lg={12}>


              <Form>

                <Card className="cardfirstheight">
                  <CardBody>
                    <Row>
                      {/* <Col lg={2}>
                        <div>
                          <Label>Exam Board & Category</Label>
                          <Select
                            value={selectedBoardName}
                            onChange={(selectedOption) => {
                              handleSelectBoardName(selectedOption);
                            }}
                            options={optionBoardName.map((option) => ({
                              label: option.categoryname,
                              value: option.exctid,
                            }))}
                            className="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col lg={2}>
                        <div>
                          <Label>Exam Paper</Label>
                          <Select
                            value={selectedExamPaper}

                            onChange={(selectedOption) => {

                              handleSelectPaper(selectedOption);
                            }}
                            options={optionExamPaper.map((option) => ({
                              label: option.exp_name,
                              value: option.expid,
                            }))}


                            className="select2-selection"

                          />


                        </div>
                      </Col>
                      <Col lg={2}>
                        <div>
                          <Label>Language</Label>
                          <Select
                            value={selectedLanguage}

                            onChange={(selectedOption) => {

                              handleSelectLanguage(selectedOption);
                            }}
                            options={optionAllLanguage.map((option) => ({
                              label: option.l_name,
                              value: option.lid,
                            }))}


                            className="select2-selection"

                          />


                        </div>
                      </Col>

                      <Col lg={2}>
                        <div>
                          <Label>Subject</Label>
                          <Select
                            value={selectedSubjectName}
                            onChange={(selectedOption) => {

                              handleSelectSubjectName(selectedOption);
                            }}
                            options={optionSubjectName.map(option => ({
                              label: option.subjectname,
                              value: `${option.subjectid}`,
                            }))}
                            styles={customStyles}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label>Subcategory</Label>
                          <Select
                            value={selectedSubCategoryName}
                            onChange={handleSelectSubCategoryName}
                            options={optionSubCategory.map((option) => ({
                              ...option,
                              label: option.ssc_name,
                              value: option.sscid,
                            }))}
                            styles={customStyles}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      {/* <Col lg={2}>
                        <div>
                          <Label>Topic</Label>
                          <Select
                            value={selectedTopicName}
                            onChange={handleSelectTopicName}
                            options={optionTopicName.map(option => ({
                              label: option.topicname,
                              value: option.topicid,
                            }))}
                            className="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col lg={1}>

                        {/* <div className="d-flex flex-wrap gap-2 justify-content-start"> */}
                        <div>
                          <Label>Status</Label>
                          <Select
                            value={selectedStatus}
                            onChange={(selectedOption) => {
                              handleSelectStatus(selectedOption);
                            }}
                            options={optionStatus.map((option) => ({
                              label: option.statusname,
                              value: option.statusid,
                            }))}
                            className="select2-selection"
                          />
                        </div>

                      </Col>
                      <Col lg={2}>
                        <div className="mb-3">
                          <Label>Approve Status</Label>
                          <Select
                            value={selectedApprove}
                            onChange={(selectedOption) => {
                              handleSelectApproveStatus(selectedOption);
                            }}
                            options={approveStatus.map((option) => ({
                              label: option.statusname,
                              value: option.statusid,
                            }))}
                            className="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col lg={8}>
                        <div className="d-flex flex-wrap gap-2 justify-content-start">
                          <Button
                            type="button"
                            style={{ marginTop: 25 }}
                            className="btn btn-soft-info"
                            onClick={() => {
                              setFilterData({
                                selectedBoardName: selectedBoardName ? selectedBoardName.value : 0,
                                selectedTopicName: selectedTopicName ? selectedTopicName.value : 0,
                                selectedSubjectName: selectedSubjectName ? selectedSubjectName.value : 0,
                                selectedSubCategoryName: selectedSubCategoryName ? selectedSubCategoryName.value : 0,
                                selectedStatus: selectedStatus ? selectedStatus.value : 0,
                                selectedApprove: selectedApprove ? selectedApprove.value : 5,
                                selectedExamPaper: selectedExamPaper ? selectedExamPaper.value : 0,
                                selectedLanguage: selectedLanguage ? selectedLanguage.value : 0
                              });
                            }}
                          >
                            Apply
                          </Button>
                          <Button
                            type="button"
                            style={{ marginTop: 25 }}
                            className="btn btn-soft-danger"
                            onClick={() => {
                              setFilterData({
                                selectedBoardName: 0,
                                selectedTopicName: 0,
                                selectedSubjectName: 0,
                                selectedSubCategoryName: 0,
                                selectedStatus: 0,
                                selectedApprove: 5,
                                selectedExamPaper: 0,
                                selectedLanguage: 0
                              });
                              setOptionSubjectName(optionAllSubjectName);
                              setOptionTopicName(optionAllTopicName);
                              setOptionExamPaper(optionAllExamPaper);
                              setOptionSubCategory(optionSubCategoryAll);
                              setSelectedSubCategoryName({ label: "All", value: 0 });
                              setselectedBoardName({ label: "All", value: 0 });
                              setselectedTopicName({ label: "All", value: 0 });
                              setselectedSubjectName({ label: "All", value: 0 });
                              setSelectedLanguage({ label: "All", value: 0 });
                              setselectedStatus({ label: "All", value: 0 });
                              setSelectedApprove({ label: "Rejected", value: 5 });
                              setselectedExamPaper({ label: "All", value: 0 });
                            }}
                          >
                            Clear
                          </Button>
                          {/* <div className="text-end"> */}
                          {/* <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-success me-2"
                                  onClick={() => {
                                    navigate('/qa-import-excel')
                                  }
                                  }
                                >
                                  Import from excel
                                </Button> */}

                          {/* <Button
                            type="button"
                            style={{ marginTop: 25 }}
                            className="btn btn-success me-2"
                            onClick={() => navigate('/studymaterial-import-excel')}
                          >
                            Import from excel
                          </Button>
                          <Button
                            type="button"
                            style={{ marginTop: 25 }}
                            className="btn btn-warning me-2"
                            onClick={exportToExcel}
                          >
                            Export excel
                          </Button> */}
                          {/* <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    localStorage.setItem('isMaterialClone', '0')
                                    navigate(
                                      `/add-studymaterial/0`
                                    )
                                  }
                                  }
                                >
                                  + Add
                                </Button> */}
                          {user && user.user_previleges && user.user_previleges.find(f =>
                            Number(f.urp_smid) === constants.SubmenuList.StudyMaterial && f.urp_type === constants.menuTypes.Create) && (
                              <Button
                                type="button"
                                style={{ marginTop: 25 }}
                                className="btn btn-secondary"
                                onClick={() => {
                                  localStorage.setItem('isMaterialClone', '0');
                                  navigate(`/add-studymaterial/0`);
                                }}
                              >
                                + Add
                              </Button>
                            )}
                          {/* <Button
                            type="button"
                            style={{ marginTop: 25 }}
                            className="btn btn-primary"
                            onClick={handleSaveClick}
                          >
                            Submit for approval
                          </Button> */}
                          {/* <button type="button" style={{ marginTop: 25 }}
                            onClick={handleSaveClick}
                            className="btn btn-primary">Submit for approval</button> */}
                        </div>
                      </Col>



                    </Row>
                  </CardBody>
                </Card>


              </Form>


              <Card className="mt-4">
                <CardBody>

                  <PaginationTableContainer
                    columns={columns}
                    data={studymaterialData || []}
                    isPagination={true}
                    isGlobalFilter={true}
                    perPageData={perPageData}
                    totalLength={totalCount}
                    currentPage={currentPage}
                    SearchPlaceholder="Search..."
                    setCurrent={setCurrent}
                    //   setCurrentPage={setCurrentPage}
                    pagination="pagination"
                    // isCustomPageSize={true}
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
StudyMaterials.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default StudyMaterials;