import React, { useMemo, useState, useEffect, useRef } from "react";
import { Card, Button, CardBody, Col, Container, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, Row, FormFeedback, CardFooter, Badge } from "reactstrap";
import { useFormik } from "formik";
import { SketchPicker } from "react-color";
import { ColorPicker } from "@vtaits/react-color-picker";
import "../../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import Select from "react-select";
import * as Yup from "yup";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getmasterlist, addblueprint, getblueprint, resetStoreblueprint, editload } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
import * as constants from "../../../constants/layout"
import "./conf.scss"

var GLOBAL_SUBJECT_LIST = [];
var GLOBAL_TOPIC_LIST = [];
const AddConfiguration = () => {

    const { cid } = useParams();
    const store = useSelector(state => state.Blueprint)
    const dispatch = useDispatch();
    const user = getLoggedInUser()
    const navigate = useNavigate();
    const confmasterstore = useSelector(state => state.ExamBoardCategory)
    const [isLoading, setLoading] = useState(false);
    const [configuration, setConfiguration] = useState(null);
    const [masterList, setmasterList] = useState([]);
    const [editdata, seteditdata] = useState([]);
    const [boardcategory, setboardcategory] = useState([]);
    const [allBoardcategory, setAllBoardcategory] = useState([]);
    const [noOfQuestions, setNoOfQuestions] = useState('');
    const [markPerQuestion, setMarkPerQuestion] = useState('');
    const [subject, setsubject] = useState([]);
    const [submitbtn, setSubmitbtn] = useState(false);
    const [initialSubmitState, setInitialSubmitState] = useState(false);
    const [c_subjects, setC_subjects] = useState([]);
    const [deleteTrigger, setDeleteTrigger] = useState(false);
    var isClone = localStorage.getItem('isClone');

    const [subjectsubcategory, setsubjectsubcategory] = useState([]);
    const [allSubjectsubcategory, setAllSubjectsubcategory] = useState([]);

    const [totalquestions, setTotalQuestions] = useState([]);
    const [allTotalQuestions, setAllTotalQuestions] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedBoardName, setselectedBoardName] = useState(null);
    const [selectedoptionName, setselectedoptionName] = useState(null);

    const [selectedBoardCategory, setselectedBoardCategory] = useState(null);
    const [selectedLanguage, setselectedLanguage] = useState(null);
    const [blueprint, setblueprint] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [negativeMark, setNegativeMark] = useState(false);
    const [status, setstatus] = useState(false);
    const [optionBoardName, setOptionBoardName] = useState([]);
    const [optionName, setOptionName] = useState([]);
    const [optionTopicName, setOptionTopicName] = useState([]);
    const [optionLanguageName, setOptionLanguageName] = useState([]);
    const [optionAllLanguageName, setOptionAllLanguageName] = useState([]);
    const [optionAllTopicName, setOptionAllTopicName] = useState([]);
    const [allSubjectList, setAllSubjectList] = useState([]);
    const [selectedPaper, setselectedPaper] = useState(null);
    const [selectedTotal, setselectedTotal] = useState(null);
    const [paperList, setPaperList] = useState([])
    const [allpaperlist, setAllpaperlist] = useState([])
    const [selectedCount, setselectedCount] = useState(null);
    const confref = useRef(null);
    const [inputFocus, setInputFocus] = useState(false);

    const paperRef = useRef(null);
    const subjectRef = useRef(null);
    const topicRef = useRef(null);

    const [totalMarks, setToalMarks] = useState(0)

    useEffect(() => {
        if (handleClose) {
            setInputFocus(false);
        } else {
            setInputFocus(true)
        }

    }, [])
    const handleEditRow = (row) => {

        setIsEditing(true);
        setEditingRow(row);

        // Set form fields with the data from the row being edited

        var filtered_subjects = GLOBAL_SUBJECT_LIST.filter(e => Number(e.expid) == Number(row.ex_paper_id))
        setsubject(filtered_subjects)
        var filtered_topic_option = GLOBAL_TOPIC_LIST.filter(e => e.subjectid == row.cs_sid && e.subcategoryid == row.cs_sscid);
        setOptionTopicName(filtered_topic_option);



        //   let subcatID = row.cs_sscid ? row.cs_sscid : 0
        validation.setFieldValue("subjectName", {
            ...row, ...{
                label: row.subjectName,
                value: row.cs_sid
            }
        });

        validation.setFieldValue("selectedPaper", {
            ...row, ...{
                label: row.ex_paper_name,
                value: row.ex_paper_id
            }
        });
        // validation.setFieldValue("subjectSubcategory", { label: row.subCategory, value: row.subCategoryId });
        validation.setFieldValue("selectedTopicName", { label: row.cs_tname, value: row.cs_tid });
        validation.setFieldValue("subjectQuestions", row.totalQuestions);
        validation.setFieldValue("markPerQuestion", row.mark);
    };

    const handleClearData = () => {
        validation.setFieldValue("selectedPaper", "");
        validation.setFieldValue("subjectName", "");
        validation.setFieldValue("subjectSubcategory", "");
        validation.setFieldValue("selectedTopicName", "");
        validation.setFieldValue("subjectQuestions", "");
        validation.setFieldValue("markPerQuestion", "");
        setIsEditing(false);
        setEditingRow('');
    }
    const handleSelectTopics = (selectedOption) => {
        var filteredcount = optionTopicName.filter(e => e.topicid == selectedOption.value)
        // setselectedCount(filteredcount)
    }

    const handleSelectLangauge = (selectedOption) => {
        var filtered_subject_option = allpaperlist.map(e => {
            var exctid_arr = e?.lang?.split(',');
            if (exctid_arr?.length > 0) {



                if (exctid_arr?.includes(JSON.stringify(selectedOption?.value))) {
                    return e;
                }


            }

        })
        // var data = paperList.filter(e => Number(e.lang) == selectedOption.value)


        filtered_subject_option = filtered_subject_option.filter(e => e != undefined);
        setPaperList(filtered_subject_option)
    }


    const handleClose = () => {
        validation.resetForm();
        setInitialSubmitState(true);
        navigate("/list-configuration");
    }

    const handleAddOrUpdateRow = () => {
        // Validate input values before adding to the table
        if (
            validation.values.selectedPaper &&
            validation.values.subjectName &&
            //  validation.values.selectedTopicName &&
            validation.values.subjectQuestions &&
            validation.values.markPerQuestion
        ) {
            const subjectQuestions = parseInt(validation.values.subjectQuestions);
            const markPerQuestion = parseFloat(validation.values.markPerQuestion);

            const product = subjectQuestions * markPerQuestion;
            const [sid, sscid] = validation.values.subjectName != null ? [Number(validation.values.subjectName.value), 0] : [0, 0];
            const [s_name, ssc_name] = validation.values.subjectName != null ? [validation.values.subjectName.label, ''] : ['', ''];
            var duplicateArray = []
            if (!isEditing) {
                duplicateArray = dataTable.filter(e => e.cs_sid == sid && e.cs_sscid == sscid)
            } else {
                duplicateArray = dataTable.filter(e => e.sno != editingRow.sno && e.cs_sid == sid && e.cs_sscid == sscid)
            }

            if (duplicateArray.length > 0) {
                toast.error("Selected subject is already added.");
                return false
            }


            // if it is add, calculate data table values and currently add values
            if (!isEditing) {
                var proposedCumulativeMarks = dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.product), 0)
                var proposedCumulativeQuestions = dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.totalQuestions), 0)
                proposedCumulativeQuestions = proposedCumulativeQuestions + parseInt(validation.values.subjectQuestions)
                proposedCumulativeMarks = proposedCumulativeMarks + parseFloat(product);
            }
            else {
                var temp_dataTable = dataTable.filter(e => e.sno != editingRow.sno)
                var proposedCumulativeMarks = temp_dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.product), 0)
                var proposedCumulativeQuestions = temp_dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.totalQuestions), 0)
                proposedCumulativeQuestions = proposedCumulativeQuestions + parseInt(validation.values.subjectQuestions)
                proposedCumulativeMarks = proposedCumulativeMarks + parseFloat(product);

            }


            // Check if adding the new row will exceed the total marks
            if (proposedCumulativeMarks <= parseInt(validation.values.totalMarks)) {
                // Check if adding the new row will exceed the total questions
                if (proposedCumulativeQuestions <= parseInt(validation.values.totalQuestions?.label)) {
                    const newRow = {
                        sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                        subjectName: s_name,
                        totalQuestions: subjectQuestions,
                        mark: markPerQuestion,
                        product: product, // Store product value in the newRow object


                        cs_sid: Number(sid),
                        s_name: s_name,
                        cs_sscid: Number(sscid),
                        ssc_name: ssc_name,
                        cs_questioncount: subjectQuestions.toString() || "0",
                        cs_mark: markPerQuestion.toString() || "0",
                        cs_tid: "0",
                        //   cs_tid: Number(validation.values.selectedTopicName.value),
                        //  cs_tname: validation.values.selectedTopicName.label,
                        cs_tname: "",
                        ex_paper_id: Number(validation.values.selectedPaper.value),
                        ex_paper_name: validation.values.selectedPaper.label

                    };

                    // Update cumulative values
                    // setCumulativeNoOfQuestions((prev) => prev + subjectQuestions);
                    // setCumulativeMarkPerQuestion((prev) => prev + product);
                    setselectedCount("")

                    if (isEditing) {

                        // Update the row in the data table
                        setDataTable((prev) =>
                            prev.map((row) => (row.sno === editingRow.sno ? { ...row, ...newRow } : row))
                        );
                        var editRow = {
                            cs_sid: Number(sid),
                            s_name: s_name,
                            cs_sscid: Number(sscid),
                            ssc_name: ssc_name,
                            cs_questioncount: subjectQuestions.toString() || "0",
                            cs_mark: markPerQuestion.toString() || "0",
                            cs_tid: "0",
                            //   cs_tid: Number(validation.values.selectedTopicName.value),
                            //  cs_tname: validation.values.selectedTopicName.label,
                            cs_tname: "",
                            ex_paper_id: Number(validation.values.selectedPaper.value),
                            ex_paper_name: validation.values.selectedPaper.label
                        }
                        setC_subjects((prev) =>

                            prev.map((row) => (row.sno === editingRow.sno ? { ...row, ...editRow } : row))
                        )
                        setIsEditing(false);
                        setEditingRow(null);
                    } else {

                        // Add a new row to the data table
                        setDataTable([...dataTable, newRow]);

                        // Push the new row into the c_subjects array
                        setC_subjects((prev) => [
                            ...prev,
                            {
                                sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                                subjectName: s_name,
                                subCategory: ssc_name,
                                totalQuestions: subjectQuestions,
                                mark: markPerQuestion,
                                product: product,
                                cs_sid: Number(sid),
                                s_name: s_name,

                                cs_sscid: Number(sscid),
                                ssc_name: ssc_name,
                                cs_questioncount: subjectQuestions.toString() || "0",
                                cs_mark: markPerQuestion.toString() || "0",
                                cs_tid: "0",
                                //  cs_tid: Number(validation.values.selectedTopicName.value),
                                cs_tname: "",
                                //  cs_tname: validation.values.selectedTopicName.label,
                                ex_paper_id: Number(validation.values.selectedPaper.value),
                                ex_paper_name: validation.values.selectedPaper.label
                            },
                        ]);

                    }

                    // Clear the form fields after adding or updating a row
                    setToalMarks(validation.values.totalMarks)
                    validation.setFieldValue("selectedPaper", null);
                    validation.setFieldValue("subjectName", null);
                    validation.setFieldValue("subjectSubcategory", null);
                    validation.setFieldValue("selectedTopicName", null);
                    validation.setFieldValue("subjectQuestions", "");
                    validation.setFieldValue("markPerQuestion", "");
                    // Display success toast
                    toast.success("Row successfully added");
                } else {
                    // Display an error toast 
                    toast.error("Total questions exceeded. Please adjust the values.");
                }
            } else {
                // Display an error toast 
                toast.error("Total marks exceeded. Please adjust the values.");
            }
        } else {
            // Display an error toast 
            toast.error("Please fill all the required fields");
        }

    };
    const handleSelectPaper = (selectedOption) => {

        var filtered_subjects = GLOBAL_SUBJECT_LIST.filter(e => Number(e.expid) == Number(selectedOption.value))
        setsubject(filtered_subjects)
        setselectedPaper(selectedOption);
        validation.setFieldValue("subjectName", null);

        var filtered_topic_option = GLOBAL_TOPIC_LIST.filter(e => e.t_expid == Number(selectedOption.value));
        setOptionTopicName(filtered_topic_option);
        validation.setFieldValue("selectedTopicName", null);
    };
    const handleSelectTotalMarks = (selectedOption) => {
        // const totalQuestionsValue = selectedOption?.label; // Adjust this based on your value structure
        validation.setFieldValue("totalQuestions", selectedOption);
    };

    const handleselectExamBoard = (selectedoption) => {

        const [exctid, exct_exid] = selectedoption.value.split('-')

        var filtered_lang = boardcategory.filter(e => e.exct_exid == exct_exid && e.exctid == exctid)

        if (optionAllLanguageName && optionAllLanguageName.length > 0) {
            var bindedlang = filtered_lang.filter(e => e.bindedlang != 0)
            var bdlang = filtered_lang.filter(e => e.bindedlang == 0)
            if (bindedlang && bindedlang.length > 0) {

                if (!Number(bindedlang[0].bindedlang.includes(0))) {

                    var result = optionAllLanguageName.filter(e => e.lid != filtered_lang.filter(e => e.bindedlang))
                    if (bindedlang && bindedlang.length > 0) {
                        var data1 = bindedlang[0].bindedlang
                        var lang = result.filter(e => e.lid != data1)
                        setOptionLanguageName(lang)
                    }

                }

            } else if (bdlang && bdlang.length > 0) {
                setOptionLanguageName(optionAllLanguageName)
            }


        }

    }

    const handleDeleteRow = (row) => {
        try {

            setDataTable(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== row.sno);
                // Return the updated data
                return updatedData;
            });
            setC_subjects(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== row.sno);
                // Return the updated data
                return updatedData;
            });

        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };

    const getmasterlistapi = () => {
        setLoading(true)
        dispatch(getmasterlist({ user_id: Number(user.uid) }));
    }

    useEffect(() => {

        getmasterlistapi()
    }, []);

    const geteditloadapi = () => {
        setLoading(true)
        dispatch(editload({ user_id: Number(user.uid), cid: Number(cid) }));
    }


    function handleSelectSubjectName(varselectedSubjectName) {
        const [sid, sscid] = [varselectedSubjectName.value, 0];
        var filtered_topic_option = GLOBAL_TOPIC_LIST.filter(e => e.subjectid == sid && e.subcategoryid == sscid);
        setOptionTopicName(filtered_topic_option);
        validation.setFieldValue("selectedTopicName", null);

        var filteredcount = subject.find(e => e.subjectid == sid)
        setselectedCount(filteredcount)

    }

    useEffect(() => {
        // alert(cid)

        document.getElementById('headerTitle').innerHTML = 'Exam Blue Print';
        geteditloadapi()
    }, [dispatch, cid]);

    // useEffect(() => {
    //     if (store.bluePrintData && store.bluePrintData.bluePrintData)
    //         seteditdata(store.bluePrintData.bluePrintData)
    // }, [])

    useEffect(() => {

        if (store.bluePrintData && store.bluePrintData.bluePrintData && store.bluePrintData.bluePrintData.length > 0) {
            const editData = store.bluePrintData.bluePrintData[0];

            validation.setFieldValue("configurationID", editData.cid);
            // validation.setFieldValue("configurationName", editData.c_name);
            validation.setFieldValue("configurationName", isClone === '1' ? "" : editData.c_name);


            // Set value for selectedBoardCategory
            const boardCategoryValue = {
                label: editData.ex_name + "-" + editData.exct_name,
                value: editData.c_exctid + "-" + editData.c_exid,
            };
            const totalValue = {
                label: editData.c_totalquestions,
            };
            setToalMarks(editData.c_totalmarks)
            const langaugevalue = {
                label: editData && editData.c_lid && editData.c_lid == 1 ? "English" : "Regional",
                value: editData.c_lid,

            }
            setselectedLanguage(langaugevalue)
            validation.setFieldValue("selectedLanguage", isClone === '1' ? langaugevalue : langaugevalue)

            validation.setFieldValue("selectedBoardCategory", isClone === '1' ? null : boardCategoryValue);
            setselectedBoardCategory(isClone === '1' ? null : boardCategoryValue);


            validation.setFieldValue("negativeMark", editData.c_negativemark === 1);

            validation.setFieldValue("totalMarks", editData.c_totalmarks);
            validation.setFieldValue("totalQuestions", totalValue);
            validation.setFieldValue("selectedoptionName", {
                label: editData.c_optionscount.toString(),
                value: editData.c_optionscount,
            });
            // Set selected option name
            setselectedoptionName({
                label: editData.c_optionscount.toString(),
                // value: editData.c_optionscount,
            });
            validation.setFieldValue("duration", editData.c_duration);

            // Set data table with the data for subjects
            const subjectsData = store.bluePrintData.bluePrintSubjects.map((subject, index) => ({
                sno: index + 1,
                subjectName: subject.s_name,
                subCategory: subject.ssc_name,
                totalQuestions: subject.cs_questioncount,
                mark: subject.cs_mark,
                product: Number(subject.cs_questioncount) * Number(subject.cs_mark),
                cs_sid: subject.cs_sid,
                cs_sscid: subject.cs_sscid,
                cs_questioncount: subject.cs_questioncount,
                cs_mark: subject.cs_mark,
                cs_tid: Number(subject.cs_tid),
                cs_tname: subject.t_name,
                ex_paper_id: Number(subject.expid),
                ex_paper_name: subject.exampaper
            }));

            // Set subjects data
            setDataTable(subjectsData);
            // Set c_subjects array
            setC_subjects(subjectsData);

            // Set status toggle only during update

            if (editData.c_stsid == "1")
                setstatus(true)
            else setstatus(false)
            if (editData.c_negativemark == 1) setNegativeMark(true)
            else setNegativeMark(false)

        }
    }, [store.bluePrintData]);
    useEffect(() => {

        console.log(optionAllTopicName, "optionAllTopicName")
    }, [optionAllTopicName])

    useEffect(() => {

        if (confmasterstore.masterlist && confmasterstore.masterlist.examBoardList)
            setmasterList(confmasterstore.masterlist.examBoardList)
        // if (confmasterstore.masterlist && confmasterstore.masterlist.board_categoryList) {
        //     var board_category_unused = confmasterstore.masterlist.board_categoryList.filter(e => e.c_exctid == 0)

        //     setboardcategory(board_category_unused)
        //     setAllBoardcategory(board_category_unused)
        // }


        if (confmasterstore.masterlist && confmasterstore.masterlist.board_categoryList) {
            if (!isEditing) {
                var filtered_board = confmasterstore.masterlist.board_categoryList.filter(e => e.flag != 0)
                setboardcategory(filtered_board)

                setAllBoardcategory(filtered_board)
            } else {
                setboardcategory(confmasterstore.masterlist.board_categoryList)
                setAllBoardcategory(confmasterstore.masterlist.board_categoryList)
            }
        }


        if (confmasterstore.masterlist && confmasterstore.masterlist.subCategryList) {
            setsubjectsubcategory(confmasterstore.masterlist.subCategryList)
            setAllSubjectsubcategory(confmasterstore.masterlist.subCategryList)
        }
        if (confmasterstore.masterlist && confmasterstore.masterlist.noofquestionList) {
            setTotalQuestions(confmasterstore.masterlist.noofquestionList)
            setAllTotalQuestions(confmasterstore.masterlist.noofquestionList)
        }

        if (confmasterstore.masterlist && confmasterstore.masterlist.topicList) {
            setOptionTopicName(confmasterstore.masterlist.topicList)
            GLOBAL_TOPIC_LIST = confmasterstore.masterlist.topicList
        }

        // if (confmasterstore.masterlist && confmasterstore.masterlist.subject_subcategoryList) {
        //     GLOBAL_SUBJECT_LIST = confmasterstore.masterlist.subject_subcategoryList;
        //     setsubject(confmasterstore.masterlist.subject_subcategoryList)
        // }

        if (confmasterstore.masterlist && confmasterstore.masterlist.subjectList) {
            GLOBAL_SUBJECT_LIST = confmasterstore.masterlist.subjectList;
            setsubject(confmasterstore.masterlist.subjectList)
        }
        if (confmasterstore.masterlist && confmasterstore.masterlist.examPaperList)
            setPaperList(confmasterstore?.masterlist?.examPaperList)
        setAllpaperlist(confmasterstore?.masterlist?.examPaperList)

        if (confmasterstore.masterlist && confmasterstore.masterlist.languageList) {
            setOptionLanguageName(confmasterstore.masterlist.languageList)
            setOptionAllLanguageName(confmasterstore.masterlist.languageList)
        }

    }, [confmasterstore.masterlist])

    const columns = useMemo(
        () => [
            {
                header: 'Actions',
                width: '8%',
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={() => { handleEditRow(cellProps.row.original) }}
                            >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>

                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    handleDeleteRow(cellProps.row.original);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Exam Paper ▼
                    </span>
                ),

                accessorKey: 'ex_paper_name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Subject ▼
                    </span>
                ),

                accessorKey: 'subjectName',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {cellProps.row.original.subjectName + (cellProps.row.original.subCategory ? ' - ' + cellProps.row.original.subCategory : '')}
                        </div>
                    );
                }
            },

            // {
            //     header: (
            //         <span style={{ cursor: 'pointer' }}>
            //             Topic ▼
            //         </span>
            //     ),

            //     accessorKey: 'cs_tname',
            //     width: '8%',
            //     enableColumnFilter: false,
            //     enableSorting: true,
            // },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        No.of Questions ▼
                    </span>
                ),

                accessorKey: 'totalQuestions',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-center'
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Mark/Question ▼
                    </span>
                ),

                accessorKey: 'mark',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-center'

            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Marks ▼
                    </span>
                ),

                accessorKey: 'product',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-center'

            }
        ],
        []
    );

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            configurationID: (configuration && configuration.configurationID) || "",
            configurationName: (configuration && configuration.configurationName) || "",
            totalMarks: (configuration && configuration.totalMarks) || "",
            totalQuestions: (configuration && configuration.totalQuestions) || null,
            duration: (configuration && configuration.duration) || "",
            selectedBoardCategory: (configuration && configuration.selectedBoardCategory) || null,
            selectedLanguage: (configuration && configuration.selectedLanguage) || null,
            // selectedoptionName: (configuration && configuration.selectedoptionName) || null

        },
        validationSchema: Yup.object({
            configurationName: Yup.string().required("Please Enter Configuration Name"),
            totalMarks: Yup.number().required("Please Enter Total Marks"),
            totalQuestions: Yup.object().required("Please select Total questions"),
            duration: Yup.number().required("Please Enter Duration"),
            selectedBoardCategory: Yup.object().required("Please select Exam Board Category"),
            selectedLanguage: Yup.object().required("Please Select Language"),
            // selectedoptionName: Yup.object().required("Please select Options")

        }),
        onSubmit: (values) => {
            var proposedCumulativeMarks = dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.product), 0)
            var proposedCumulativeQuestions = dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.totalQuestions), 0)
            if (proposedCumulativeMarks != validation.values.totalMarks || proposedCumulativeQuestions != Number(validation.values.totalQuestions?.label)) {
                toast.error("Marks or Questions are not fulfilled.");
                return false;
            }

            setSubmitbtn(true);
            setLoading(true);
            const [c_exctid, c_exid] = selectedBoardCategory.value.split('-');
            var isClone = localStorage.getItem('isClone');
            const newblueprint = {
                user_id: Number(user.uid),
                c_name: values.configurationName,
                c_exid: Number(c_exid),
                c_exctid: Number(c_exctid),
                c_negativemark: negativeMark ? 1 : 2,
                c_totalmarks: values.totalMarks,
                c_totalquestions: Number(values.totalQuestions?.label),
                c_optionscount: 5,
                c_lid: selectedLanguage.value,
                c_duration: values.duration,
                c_subjects: c_subjects,
                cid: isClone == '1' ? '0' : (cid),

                c_stsid: status ? 1 : 2,
                process_type: cid == 0 || isClone == '1' ? 'save' : 'update',
            };

            // Dispatch the action
            dispatch(addblueprint(newblueprint))

                .then(() => {

                    navigate("/list-configuration");
                    // Reset the form and state if needed

                })
                .catch((error) => {
                    // Handle error if needed
                    console.error("Error:", error);
                });
        },
    });


    useEffect(() => {
        // return () => {
        validation.resetForm();
        setDataTable([])
        setC_subjects([])
        // };
    }, []);

    //After api call success stop loading
    useEffect(() => {
        if (store.blueprint_statusFlag === 1) {
            if (submitbtn) { // add or update then refresh list
                setSubmitbtn(false)

                validation.resetForm();
                setInitialSubmitState(true);
                navigate("/list-configuration");
            }
            if (deleteTrigger) { // delete then refresh list
                setDeleteTrigger(false)

                setDeleteModal(false);
                setBoard('');
            }
            setLoading(false)
            dispatch(resetStoreblueprint({ statusFlag: 0 }));
        } else if (store.blueprint_statusFlag === 2) {
            if (submitbtn) {
                setSubmitbtn(false)
            }
            if (deleteTrigger) {
                setDeleteTrigger(false)
            }
            setLoading(false)
            dispatch(resetStoreblueprint({ statusFlag: 0 }));
        }
    }, [store.blueprint_statusFlag])

    const customStyles = {
        option: (styles, state, data) => { // fixed
            return {
                ...styles,
                backgroundColor: state.isDisabled
                    ? undefined
                    : state.isSelected
                        ? '#a6b0cf57'
                        : state.isFocused
                            ? '#556ee6'
                            : 'white',

                color: state.isDisabled
                    ? '#ccc'
                    : state.isSelected
                        ? 'black'
                        : state.isFocused
                            ? 'white'
                            : 'black',

                cursor: state.isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled
                        ? state.isSelected
                            ? '#556ee6'
                            : '#556ee6'
                        : undefined,
                },
                fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
            };
        }
    };

    // useEffect(() => {
       
    //     if (isEditing) {
    //         if (validation.values.subjectQuestions || validation.values.markPerQuestion) {
    //             if (totalMarks > 0) {
    //                 var total_marks = totalMarks - (validation.values.subjectQuestions * validation.values.markPerQuestion);
    //                 validation.setFieldValue('totalMarks', total_marks);
    //                 console.log('total_marks', total_marks);
    //                 console.log('total_marks', totalMarks);
    //             } else {
    //                 var total_marks = totalMarks + (validation.values.subjectQuestions * validation.values.markPerQuestion);
    //                 validation.setFieldValue('totalMarks', total_marks)
    //                 console.log('total_marks', total_marks);
    //                 console.log('total_marks', totalMarks);
    //             }
    //         }
    //     } else {
    //         if (validation.values.subjectQuestions || validation.values.markPerQuestion) {
    //             var total_marks = totalMarks + (validation.values.subjectQuestions * validation.values.markPerQuestion);
    //             validation.setFieldValue('totalMarks', total_marks)
              
    //         }
    //     }
    // }, [validation.values.subjectQuestions, validation.values.markPerQuestion]);

    //meta title
    // document.title = "Exam Blue Print | Create Blue Print"
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid={true}>
                    {/* <Breadcrumbs title="Exam Blue Print" breadcrumbItem="Create Blue Print" /> */}

                    <Row>
                        <Col lg="12">
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                }}
                            >
                                <Card className="crd-min-ht">
                                    <CardBody>

                                        <form>
                                            <Row>
                                                <Col lg="3">
                                                    <Row>
                                                        <Col lg="7">
                                                            <div className="mb-3">
                                                                <Label>Configuration Name<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    name="configurationName"
                                                                    type="text"
                                                                    // maxLength={constants.textboxLength.c_name}
                                                                    placeholder="Configuration Name"
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    //  defaultValue={isClone === '1' ? "" : validation.values.configurationName}
                                                                    value={validation.values.configurationName}
                                                                    invalid={
                                                                        validation.touched.configurationName && validation.errors.configurationName ? true : false
                                                                    }
                                                                    innerRef={confref}

                                                                />
                                                                {validation.touched.configurationName && validation.errors.configurationName ? (
                                                                    <FormFeedback type="invalid">{validation.errors.configurationName}</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                            <div className="mb-3">
                                                                <Label>Exam Board Category<span style={{ color: 'red' }}>*</span></Label>
                                                                <Select
                                                                    value={validation.values.selectedBoardCategory}
                                                                    onChange={(selectedOption) => {
                                                                        validation.setFieldValue("selectedBoardCategory", selectedOption);
                                                                        setselectedBoardCategory(selectedOption);
                                                                        handleselectExamBoard(selectedOption)
                                                                    }}
                                                                    options={boardcategory.map((board) => ({
                                                                        label: board.categoryname,
                                                                        value: board.exctid + "-" + board.exct_exid,
                                                                    }))}
                                                                    className={`select2-selection ${validation.touched.selectedBoardCategory && validation.errors.selectedBoardCategory ? 'is-invalid' : ''}`}
                                                                    isDisabled={cid > 0 && isClone === '0'}
                                                                />
                                                                {validation.touched.selectedBoardCategory && validation.errors.selectedBoardCategory ? (
                                                                    <div className="invalid-feedback">{validation.errors.selectedBoardCategory}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="mb-3">
                                                                <Label>Negative Mark for Wrong Answer</Label>
                                                                <div className="square-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="negativeMark"
                                                                        className="switch switch-info"
                                                                        checked={negativeMark}
                                                                        onClick={() => setNegativeMark(!negativeMark)}
                                                                    />
                                                                    <label htmlFor="negativeMark" data-on-label="Yes" data-off-label="No" />
                                                                </div>
                                                            </div>


                                                            <div className="mb-3">
                                                                <Label>Languages<span style={{ color: 'red' }}>*</span></Label>
                                                                <Select
                                                                    isDisabled={cid > 0 && isClone === '0'}
                                                                    value={validation.values.selectedLanguage}
                                                                    onChange={(selectedOption) => {
                                                                        handleSelectLangauge(selectedOption);
                                                                        validation.setFieldValue("selectedLanguage", selectedOption);
                                                                        setselectedLanguage(selectedOption);
                                                                    }}
                                                                    options={
                                                                        optionLanguageName.map((board) => ({
                                                                            label: board.l_name,
                                                                            value: board.lid,
                                                                        }))}
                                                                    className={`select2-selection ${validation.touched.selectedLanguage && validation.errors.selectedLanguage ? 'is-invalid' : ''}`}
                                                                />
                                                                {validation.touched.selectedLanguage && validation.errors.selectedLanguage ? (
                                                                    <div className="invalid-feedback">{validation.errors.selectedLanguage}</div>
                                                                ) : null}
                                                            </div>

                                                            {cid > 0 && isClone === '0' && (
                                                                <div className="mb-3">
                                                                    <Label>Status</Label>
                                                                    <div className="square-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="statusToggle"
                                                                            className="switch switch-info"
                                                                            checked={status}
                                                                            onClick={() => setstatus(!status)}
                                                                        />
                                                                        <label htmlFor="statusToggle" data-on-label="Active" data-off-label="Inactive" />
                                                                    </div>
                                                                </div>
                                                            )}

                                                        </Col>
                                                        <Col lg="5">
                                                            <div className="mb-3">
                                                                <Label>Total Marks<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    className="rightAlignText"
                                                                    name="totalMarks"
                                                                    type="number"
                                                                    min="1"
                                                                    max="999"
                                                                    onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        if (value.length > 3) {
                                                                            value = value.slice(0, 3);
                                                                        }
                                                                        if (parseInt(value) > 999) {
                                                                            value = "999";
                                                                        }
                                                                        e.target.value = value;
                                                                        validation.handleChange(e);
                                                                    }}
                                                                    onBlur={validation.handleBlur}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault();
                                                                        const pastedText = e.clipboardData.getData('text');
                                                                        let pastedValue = parseFloat(pastedText);
                                                                        if (isNaN(pastedValue) || pastedValue < 0) {
                                                                            pastedValue = "";
                                                                        } else if (pastedValue > 999) {
                                                                            pastedValue = 999;
                                                                        }
                                                                        e.target.value = pastedValue.toString().slice(0, 3);
                                                                        validation.handleChange(e);
                                                                    }}
                                                                    value={validation.values.totalMarks || ""}
                                                                    invalid={validation.touched.totalMarks && validation.errors.totalMarks ? true : false}
                                                                />
                                                                {validation.touched.totalMarks && validation.errors.totalMarks ? (
                                                                    <FormFeedback type="invalid">{validation.errors.totalMarks}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="mb-3">
                                                                <Label>
                                                                    Total Questions<span style={{ color: 'red' }}>*</span>
                                                                </Label>
                                                                <Select
                                                                    value={validation.values.totalQuestions}
                                                                    onChange={
                                                                        (selectedOption) => {
                                                                            validation.setFieldValue("totalQuestions", selectedOption?.label);
                                                                            setselectedTotal(selectedOption);

                                                                            handleSelectTotalMarks(selectedOption)
                                                                        }
                                                                    }


                                                                    // handleSelectTotalMarks}
                                                                    options={totalquestions.map(option => ({
                                                                        label: option.total_questions,
                                                                        value: option.tqid,
                                                                    }))}
                                                                    styles={customStyles}
                                                                    className={`select2-selection ${validation.touched.totalQuestions && validation.errors.totalQuestions ? 'is-invalid' : ''}`}
                                                                />
                                                                {validation.touched.totalQuestions && validation.errors.totalQuestions ? (
                                                                    <FormFeedback type="invalid">{validation.errors.totalQuestions}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                            {/* <div className="mb-3">
                                                                <Label>No. of Options<span style={{ color: 'red' }}>*</span></Label>
                                                                <Select
                                                                    value={validation.values.selectedoptionName}
                                                                    onChange={(selectedOption) => {
                                                                        validation.setFieldValue("selectedoptionName", selectedOption);
                                                                        setselectedoptionName(selectedOption);
                                                                    }}
                                                                    options={[
                                                                        { label: '4', value: 4 },
                                                                        { label: '5', value: 5 },
                                                                    ]}
                                                                    className={`select2-selection ${validation.touched.selectedoptionName && validation.errors.selectedoptionName ? 'is-invalid' : ''}`}
                                                                    isDisabled={cid > 0 && isClone === '0'}
                                                                />
                                                                {validation.touched.selectedoptionName && validation.errors.selectedoptionName ? (
                                                                    <div className="invalid-feedback">{validation.errors.selectedoptionName}</div>
                                                                ) : null}
                                                            </div> */}
                                                            <div className="mb-3">
                                                                <Label>Duration(Mins)<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    className="rightAlignText"

                                                                    name="duration"
                                                                    type="number"
                                                                    min="1"
                                                                    max="999"
                                                                    onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        if (value.length > 3) {
                                                                            value = value.slice(0, 3);
                                                                        }
                                                                        if (parseInt(value) > 999) {
                                                                            value = "999";
                                                                        }
                                                                        e.target.value = value;
                                                                        validation.handleChange(e);
                                                                    }}
                                                                    onBlur={validation.handleBlur}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault();
                                                                        const pastedText = e.clipboardData.getData('text');
                                                                        let pastedValue = parseFloat(pastedText);
                                                                        if (isNaN(pastedValue) || pastedValue < 0) {
                                                                            pastedValue = "";
                                                                        } else if (pastedValue > 999) {
                                                                            pastedValue = 999;
                                                                        }
                                                                        e.target.value = pastedValue.toString().slice(0, 3);
                                                                        validation.handleChange(e);
                                                                    }}
                                                                    value={validation.values.duration || ""}
                                                                    invalid={validation.touched.duration && validation.errors.duration ? true : false}
                                                                />
                                                                {validation.touched.duration && validation.errors.duration ? (
                                                                    <FormFeedback type="invalid">{validation.errors.duration}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg="9" style={{
                                                    backgroundColor: 'rgb(244 245 249)',
                                                    padding: 16
                                                }}>

                                                    <Row>
                                                        <Col lg="2">

                                                            <div className="mb-3">
                                                                <Label style={{ fontSize: '12px' }}>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                                                                <Select
                                                                    ref={paperRef}
                                                                    value={validation.values.selectedPaper}
                                                                    onChange={(selectedOption) => {
                                                                        handleSelectPaper(selectedOption);
                                                                        validation.setFieldValue("selectedPaper", selectedOption)
                                                                    }}
                                                                    options={paperList.map((paper) => ({
                                                                        label: paper.exp_name,
                                                                        value: paper.expid,
                                                                    }))}
                                                                    styles={customStyles}
                                                                    className={`select2-selection ${validation.touched.selectedPaper && validation.errors.selectedPaper ? 'is-invalid' : ''}`}
                                                                />
                                                                {validation.touched.selectedPaper && validation.errors.selectedPaper ? (
                                                                    <FormFeedback type="invalid">{validation.errors.selectedPaper}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg="3">
                                                            <div className="mb-2">
                                                                <Label style={{ fontSize: '12px' }}>Subject<span style={{ color: 'red' }}>*</span></Label>
                                                                <Select
                                                                    ref={subjectRef}
                                                                    value={validation.values.subjectName}
                                                                    onChange={(selectedOption) => {
                                                                        validation.setFieldValue("subjectName", selectedOption);
                                                                        handleSelectSubjectName(selectedOption);
                                                                    }}
                                                                    options={subject.map((option) => ({
                                                                        ...option,
                                                                        label: option.subjectname,
                                                                        value: option.subjectid,
                                                                    }))}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}

                                                                    styles={customStyles}
                                                                    className={`select2-selection ${validation.touched.subjectName && validation.errors.subjectName ? 'is-invalid' : ''}`}
                                                                />
                                                                {selectedCount ?
                                                                    <Badge pill color="success" className="me-1 qount">
                                                                        Available Questions :  <b>{selectedCount ? (selectedCount?.qcount || "") : ""}</b>
                                                                    </Badge> : ""}
                                                                {validation.touched.subjectName && validation.errors.subjectName ? (
                                                                    <div className="invalid-feedback">{validation.errors.subjectName}</div>
                                                                ) : null}


                                                            </div>
                                                        </Col>
                                                        {/* <Col lg="3">
                                                            <div className="mb-3">
                                                                <Label style={{ fontSize: '12px' }}>Topic  <span style={{ color: 'red' }}>*</span></Label>
                                                                <Select
                                                                    ref={topicRef}
                                                                    value={validation.values.selectedTopicName}
                                                                    onChange={(selectedOption) => {
                                                                        validation.setFieldValue("selectedTopicName", selectedOption);
                                                                        handleSelectTopics(selectedOption)
                                                                    }}
                                                                    options={optionTopicName.map((option) => ({
                                                                        label: option.topicname,
                                                                        value: option.topicid,
                                                                    }))}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}

                                                                    className={`select2-selection ${validation.touched.selectedTopicName && validation.errors.selectedTopicName ? 'is-invalid' : ''}`}
                                                                />
                                                                     {selectedCount && selectedCount.length > 0 ?
                                                                            <Badge pill color="success" className="me-1 qount">
                                                                                Available Questions :  <b>{selectedCount && selectedCount.length > 0 ? (selectedCount[0].qcount || "") : ""}</b>
                                                                            </Badge> : ""}
                                                                {validation.touched.selectedTopicName && validation.errors.selectedTopicName ? (
                                                                    <div className="invalid-feedback">{validation.errors.selectedTopicName}</div>
                                                                ) : null}

                                                            </div>

                                                        </Col> */}
                                                        <Col lg="4">
                                                            <Row>
                                                                <Col lg="5">
                                                                    <Label style={{ fontSize: '12px' }}>No.of Questions<span style={{ color: 'red' }}>*</span></Label>
                                                                    <Input
                                                                        className="rightAlignText"
                                                                        name="subjectQuestions"
                                                                        type="number"
                                                                        min="1"
                                                                        validate={{ required: { value: true } }}
                                                                        max="999"
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            if (value.length > 3) {
                                                                                value = value.slice(0, 3);
                                                                            }
                                                                            if (parseInt(value) > 999) {
                                                                                value = "999";
                                                                            }
                                                                            e.target.value = value;
                                                                            validation.handleChange(e);
                                                                        }}
                                                                        onBlur={validation.handleBlur}
                                                                        onKeyPress={(e) => {
                                                                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        onPaste={(e) => {
                                                                            e.preventDefault();
                                                                            const pastedText = e.clipboardData.getData('text');
                                                                            let pastedValue = parseFloat(pastedText);
                                                                            if (isNaN(pastedValue) || pastedValue < 0) {
                                                                                pastedValue = "";
                                                                            } else if (pastedValue > 999) {
                                                                                pastedValue = 999;
                                                                            }
                                                                            e.target.value = pastedValue.toString().slice(0, 3);
                                                                            validation.handleChange(e);
                                                                        }}
                                                                        value={validation.values.subjectQuestions || ""}
                                                                        invalid={validation.touched.subjectQuestions && validation.errors.subjectQuestions ? true : false}
                                                                    />
                                                                    {validation.touched.subjectQuestions && validation.errors.subjectQuestions ? (
                                                                        <FormFeedback type="invalid">{validation.errors.subjectQuestions}</FormFeedback>
                                                                    ) : null}
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <i className="mdi mdi-timer-sand" style={{ fontSize: '18px', marginRight: '10px' }}></i>
                                                                        <div style={{ fontSize: '8px', color: 'black', marginTop: '8px' }}>
                                                                            {/* {cumulativeNoOfQuestions}/{validation.values.totalQuestions || ''} */}

                                                                            {dataTable.reduce((acc, row) => Number(acc) + (Number(row.totalQuestions) || 0), 0)} / {validation.values.totalQuestions?.label || ''}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <Label style={{ fontSize: '12px' }}>Mark/Question<span style={{ color: 'red' }}>*</span></Label>
                                                                    <Input
                                                                        className="rightAlignText"

                                                                        name="markPerQuestion"
                                                                        type="number"
                                                                        min="1"
                                                                        validate={{ required: { value: true } }}
                                                                        max="999"
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            if (value.length > 4) {
                                                                                value = value.slice(0, 4);
                                                                            }

                                                                            e.target.value = value;
                                                                            validation.handleChange(e);
                                                                        }}
                                                                        onBlur={validation.handleBlur}
                                                                        onKeyPress={(e) => {
                                                                            if (e.key === '-' || e.key === 'e') {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        onPaste={(e) => {
                                                                            e.preventDefault();
                                                                            const pastedText = e.clipboardData.getData('text');
                                                                            let pastedValue = parseFloat(pastedText);
                                                                            if (isNaN(pastedValue) || pastedValue < 0) {
                                                                                pastedValue = "";
                                                                            } else if (pastedValue > 999) {
                                                                                pastedValue = 999;
                                                                            }
                                                                            e.target.value = pastedValue.toString().slice(0, 4);
                                                                            validation.handleChange(e);
                                                                        }}
                                                                        value={validation.values.markPerQuestion || ""}
                                                                        invalid={validation.touched.markPerQuestion && validation.errors.markPerQuestion ? true : false}
                                                                    />
                                                                    {validation.touched.markPerQuestion && validation.errors.markPerQuestion ? (
                                                                        <FormFeedback type="invalid">{validation.errors.markPerQuestion}</FormFeedback>
                                                                    ) : null}
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <i className="mdi mdi-timer-sand" style={{ fontSize: '18px', marginRight: '10px' }}></i>
                                                                        <div style={{ fontSize: '8px', color: 'black', marginTop: '8px' }}>
                                                                            {dataTable.reduce((acc, row) => acc + (row.product || 0), 0)} / {validation.values.totalMarks || ''}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="2" style={{ padding: '0', marginTop: '7%' }}>
                                                                    <Button
                                                                        // color="success"
                                                                        type="button"
                                                                        style={{
                                                                            color: "white",
                                                                            background: "transparent",
                                                                            border: "transparent",
                                                                            padding: "0px",
                                                                            fontSize: "25px",
                                                                            height: "25px"
                                                                        }}
                                                                        // className="btn btn-success btn-rounded waves-effect waves-light addOrder-modal"
                                                                        // style={{ fontSize: '12px', fontWeight: 'bold', marginRight: '5px!important' }}
                                                                        onClick={handleAddOrUpdateRow}
                                                                    >
                                                                        {isEditing ? <i className="mdi mdi-check-circle text-success me-2"></i> : <i className="mdi mdi-plus-circle text-success me-2"></i>}
                                                                    </Button>
                                                                    {isEditing && (
                                                                        <Button
                                                                            // color="danger"
                                                                            type="button"
                                                                            style={{
                                                                                color: "white",
                                                                                background: "transparent",
                                                                                border: "transparent",
                                                                                padding: "0px",
                                                                                fontSize: "25px",
                                                                                height: "25px"
                                                                            }}
                                                                            // className="btn btn-danger btn-rounded waves-effect waves-light"
                                                                            // style={{ fontSize: '12px', fontWeight: 'bold', marginLeft: "5px" }}
                                                                            onClick={handleClearData}
                                                                        >
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </Button>)}
                                                                </Col>
                                                            </Row>


                                                        </Col>
                                                        {/* <Col lg="1">
                                                            <Label>Mark/Question<span style={{ color: 'red' }}>*</span></Label>
                                                            <Input
                                                                className="rightAlignText"

                                                                name="markPerQuestion"
                                                                type="number"
                                                                min="1"
                                                                validate={{ required: { value: true } }}
                                                                max="999"
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    if (value.length > 4) {
                                                                        value = value.slice(0, 4);
                                                                    }

                                                                    e.target.value = value;
                                                                    validation.handleChange(e);
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === '-' || e.key === 'e') {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault();
                                                                    const pastedText = e.clipboardData.getData('text');
                                                                    let pastedValue = parseFloat(pastedText);
                                                                    if (isNaN(pastedValue) || pastedValue < 0) {
                                                                        pastedValue = "";
                                                                    } else if (pastedValue > 999) {
                                                                        pastedValue = 999;
                                                                    }
                                                                    e.target.value = pastedValue.toString().slice(0, 4);
                                                                    validation.handleChange(e);
                                                                }}
                                                                value={validation.values.markPerQuestion || ""}
                                                                invalid={validation.touched.markPerQuestion && validation.errors.markPerQuestion ? true : false}
                                                            />
                                                            {validation.touched.markPerQuestion && validation.errors.markPerQuestion ? (
                                                                <FormFeedback type="invalid">{validation.errors.markPerQuestion}</FormFeedback>
                                                            ) : null}
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <i className="mdi mdi-timer-sand" style={{ fontSize: '18px', marginRight: '10px' }}></i>
                                                                <div style={{ fontSize: '10px', color: 'black', marginTop: '8px' }}>
                                                                    {dataTable.reduce((acc, row) => acc + (row.product || 0), 0)} / {validation.values.totalMarks || ''}
                                                                </div>
                                                            </div>
                                                        </Col> */}



                                                    </Row>
                                                    <Row>

                                                        <TableContainer
                                                            columns={columns}
                                                            data={dataTable || []}
                                                            pageSize={dataTable.length}
                                                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"

                                                            isPagination={true}


                                                            pagination="pagination"

                                                            // isCustomPageSize={true}

                                                            buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                            theadClass="table-light"


                                                        />
                                                    </Row>

                                                </Col>

                                            </Row>
                                        </form>

                                    </CardBody>
                                    <CardFooter>

                                        <div className="text-end">
                                            <Button color="success"
                                                type="submit"
                                                className="save-user"
                                                disabled={initialSubmitState}
                                            >
                                                {cid > 0 && isClone === '0' ? 'Update' : 'Save'}
                                            </Button>
                                            <button type="button" style={{ marginLeft: "10px" }}
                                                onClick={handleClose}
                                                className="btn btn-danger">Close</button>
                                        </div>

                                    </CardFooter>

                                </Card>
                            </Form>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddConfiguration;
