import React, { useRef, useState, createRef, useEffect, useLayoutEffect } from "react";
import { Card, Button, CardBody, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane, CardText, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, Row, FormFeedback, CardFooter, Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import "../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import PaginationTableContainer from 'components/Common/PaginationTableContainer';
const ExcelJS = require('exceljs');
import * as XLSX from 'xlsx';
const StudyMaterialImportExcel = () => {

    const navigate = useNavigate();

    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'Study Material Import Excel';
    }, []);

    const exportToExcel = async () => {

        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Q&A List')

        // Save the workbook
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `Q&A List.xlsx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const [data, setData] = React.useState(null);

    const handleFileUpload = (e) => {
        console.log('setData');
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet);
            console.log('setData', sheetData);
            setData(sheetData);
        };
        reader.readAsBinaryString(file);
    };

    // const columns = useMemo(
    //     () => [
    //         {
    //             header: 'Actions',
    //             width: '8%',
    //             cell: (cellProps) => {
    //                 return (
    //                     <div className="d-flex gap-3">
    //                         <div
    //                             to="#"
    //                             className="btn btn-sm btn-soft-info"
    //                             onClick={() => {
    //                                 localStorage.setItem('isClone', '0')
    //                                 navigate(
    //                                     `/add-questions/${cellProps.row.original.qid}`
    //                                 )
    //                             }
    //                             }

    //                         >
    //                             <i className="mdi mdi-pencil-outline" id="edittooltip" />
    //                             <UncontrolledTooltip placement="top" target="edittooltip">
    //                                 Edit
    //                             </UncontrolledTooltip>
    //                         </div>
    //                         <div
    //                             to="#"
    //                             className="btn btn-sm btn-soft-danger"
    //                             onClick={() => {
    //                                 onClickDelete(cellProps.row.original.qid);
    //                             }}>
    //                             <i className="mdi mdi-delete-outline" id="deletetooltip" />
    //                             <UncontrolledTooltip placement="top" target="deletetooltip">
    //                                 Delete
    //                             </UncontrolledTooltip>
    //                         </div>
    //                     </div>
    //                 );
    //             }
    //         },
    //         {
    //             header: 'S.No.',
    //             accessorKey: 'sno',
    //             width: '8%',
    //             enableColumnFilter: false,
    //             enableSorting: true,
    //             className: 'pl-25',
    //             cell: ({ row, table }) =>
    //                 (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
    //         },
    //         {
    //             header: (
    //                 <span style={{ cursor: 'pointer' }}>
    //                     Question Text ▼
    //                 </span>
    //             ),

    //             accessorKey: 'question',
    //             enableColumnFilter: false,
    //             enableSorting: true,
    //             cell: (cellProps) => {
    //                 var question_text = '';
    //                 const question = cellProps.row.original.question;
    //                 const created = cellProps.row.original.qid;

    //                 if (question.length > 50) {
    //                     var question_text = question.substring(0, 50 - 3) + '...';
    //                 }

    //                 return (
    //                     <div className="d-flex align-items-center" id={'question' + created}>
    //                         {question_text !== '' ? question_text : question}
    //                         {question_text !== '' ? <UncontrolledTooltip placement="top" target={'question' + created}>
    //                             {question}
    //                         </UncontrolledTooltip> : ''}
    //                     </div>
    //                 );
    //             },
    //         },
    //         {
    //             header: (
    //                 <span style={{ cursor: 'pointer' }}>
    //                     Exam Level ▼
    //                 </span>
    //             ),

    //             accessorKey: 'exl_name',
    //             enableColumnFilter: false,
    //             enableSorting: true,
    //         },
    //         {
    //             header: (
    //                 <span style={{ cursor: 'pointer' }}>
    //                     Languages ▼
    //                 </span>
    //             ),

    //             accessorKey: 'l_name',
    //             enableColumnFilter: false,
    //             enableSorting: true,
    //         },

    //         {
    //             header: (
    //                 <span style={{ cursor: 'pointer' }}>
    //                     Created By ▼
    //                 </span>
    //             ),

    //             accessorKey: 'created',
    //             enableColumnFilter: false,
    //             enableSorting: true,
    //         },
    //         {
    //             header: 'Status',
    //             accessorKey: 'status',
    //             enableColumnFilter: false,
    //             enableSorting: true,
    //             cell: (cellProps) => {
    //                 const c_stsid = cellProps.row.original.statuscode;

    //                 return (
    //                     <div className="d-flex align-items-center">
    //                         {c_stsid == "1" ? (
    //                             <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
    //                         ) : c_stsid == "2" ? (
    //                             <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
    //                         ) : c_stsid == "3" ? (
    //                             <i className="mdi mdi-book-clock-outline text-warning me-2" style={{ fontSize: '25px' }} />
    //                         ) : ""}
    //                     </div>
    //                 );
    //             },
    //         },
    //         {
    //             header: (
    //                 <span style={{ cursor: 'pointer' }}>
    //                     Updated By ▼
    //                 </span>
    //             ),

    //             accessorKey: 'updated',
    //             // enableColumnFilter: false,
    //             enableColumnFilter: false,
    //             enableSorting: true,
    //             cell: (cellProps) => {
    //                 const mk_stsid = cellProps.row.original.updated;

    //                 return (
    //                     <div className="d-flex align-items-center">
    //                         {mk_stsid ? (
    //                             <span>{mk_stsid}</span>
    //                         ) : (
    //                             <span>-</span>
    //                         )}
    //                     </div>
    //                 );
    //             },
    //         }

    //     ],
    //     []
    // );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Card className="crd-min-ht">
                        <CardBody>
                            <Row>
                                <Col lg='4'>
                                    <div className="mb-3">
                                        <Label>File<span style={{ color: 'red' }}>*</span></Label>
                                        <Input
                                            name="filename"
                                            type="text"
                                            // maxLength={constants.textboxLength.t_name}
                                            placeholder="File Name"
                                            // validate={{
                                            //     required: { value: true },
                                            // }}
                                            autoComplete="off"
                                        // innerRef={inputtopRef}

                                        // onChange={validation.handleChange}
                                        // onBlur={validation.handleBlur}
                                        // value={validation.values.topicName || ""}
                                        // invalid={
                                        //     validation.touched.topicName && validation.errors.topicName ? true : false
                                        // }
                                        />
                                        {/* {validation.touched.topicName && validation.errors.topicName ? (
                                            <FormFeedback type="invalid">{validation.errors.topicName}</FormFeedback>
                                        ) : null} */}
                                    </div>
                                </Col>

                                <Col lg="3">
                                    <Label>Upload <span>(jpg,jpeg,png,pdf only)</span><span style={{ color: 'red' }}>*</span></Label>
                                    <Input type="file" id="regfileuplaod" name="regfileuplaod"
                                        onChange={handleFileUpload} />
                                </Col>

                                <Col lg="2">
                                    <Button
                                        type="button"
                                        className="btn btn-warning"
                                        style={{ marginTop: '12%' }}
                                        onClick={() => {
                                            exportToExcel()
                                        }
                                        }
                                    >
                                        Template Download
                                        {/* <i style={{ marginTop: '10%' }} className="mdi mdi-arrow-down-bold-circle-outline" /> */}
                                    </Button>
                                </Col>
                            </Row>

                            {/* <input type="file" onChange={handleFileUpload} />
                            {data && (
                                <div>
                                    <h2>Imported Data:</h2>
                                    <pre>{JSON.stringify(data, null, 2)}</pre>
                                </div>
                            )} */}

                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {/* <PaginationTableContainer
                                            columns={columns}
                                            data={qaList || []}
                                            isPagination={true}
                                            // isGlobalFilter={true}
                                            perPageData={perPageData}
                                            totalLength={totalCount}
                                            currentPage={currentPage}
                                            // SearchPlaceholder="Search..."
                                            //   setCurrentPage={setCurrentPage}
                                            pagination="pagination"
                                            // isCustomPageSize={true}
                                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                            theadClass="table-light"
                                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                        /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </CardBody>
                        <CardFooter>
                            <Row className="mb-3">
                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                    <button type="button" style={{ marginLeft: "10px" }}
                                        onClick={() => navigate('/list-studymaterial')}
                                        className="btn btn-danger">Close</button>
                                </div>
                            </Row>
                        </CardFooter>
                    </Card>
                </Container>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment>
    )

}

export default StudyMaterialImportExcel;