export const GET_SUBJECTS = "GET_SUBJECTS"
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS"
export const ADD_SUBJECT = "ADD_SUBJECT"
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS"
export const RESET_STORE_SUBJECT = "RESET_STORE_SUBJECT"
export const DELETE_SUBJECT = "DELETE_SUBJECT"
export const DELETE_SUBJECT_SUCCESS = "DELETE_SUBJECT_SUCCESS"
export const UPDATE_VALUE = "UPDATE_VALUE"
export const RESET_SUBJECT = "RESET_SUBJECT"

