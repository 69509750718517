import React, { useRef, useState, createRef, useEffect, useLayoutEffect, useMemo } from "react";
import { Card, Button, CardBody, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane, CardText, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, Row, FormFeedback, CardFooter, Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import "../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import PaginationTableContainer from 'components/Common/PaginationTableContainer';
import { Collapse } from 'reactstrap';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Spinners from "components/Common/Spinner";
import moment from 'moment';
import { getRefreshToken, getLoggedInUser } from "helpers/fakebackend_helper";
import { logoutUser } from "store/actions";
import * as url from "helpers/url_helper";
import * as constants from "constants/layout"
import TableContainer from "components/Common/TableContainer";
import { getUserroleList } from "store/actions";
import { useSelector, useDispatch } from "react-redux";

const LoginTransaction = () => {

    const dispatch = useDispatch();
    const store = useSelector(state => state.Userrole)
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const toggleFilter = () => setIsFilterOpen(!isFilterOpen);
    const [searchData, setSearchData] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [selectedUser, setSelectedUser] = useState({ label: 'All', value: 0 });
    const [selectedUserRole, setSelectedUserRole] = useState({ label: 'All', value: 0 });
    const [userOptions, setUserOptions] = useState([]);
    const [userOptionsAll, setUserOptionsAll] = useState([]);
    const [userRoleOptions, setUserRoleOptions] = useState([]);
    const [current, setCurrent] = useState(0);

    const [selectedFromDate, setSelectedFromDate] = useState(new Date());
    const [selectedToDate, setSelectedToDate] = useState(new Date());
    const [isLoading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const perPageData = 8;
    const indexOfLast = currentPage * perPageData;
    const [userLogList, setUserLogList] = useState([]);
    const [loginTransaction, setLoginTransaction] = useState(1);

    const [filterData, setFilterData] = useState({
        selectedUser: 0,
        selectedUserRole: 0,
        selectedFromDate: new Date(),
        selectedToDate: new Date()
    });

    useEffect(() => {
        document.title = "Login Transactions | TNPSC";
        document.getElementById('headerTitle').innerHTML = 'Login Transactions';
        localStorage.removeItem('selectedSubjectName')
        localStorage.removeItem('selectedExamPaper')
        localStorage.removeItem('selectedSubCategoryName')
        localStorage.removeItem('selectedTopicName')
        localStorage.removeItem('applyButton')
        localStorage.removeItem('selectedStatus')
        localStorage.removeItem('selectedLanguage')
    }, []);

    const handleFromDateChange = (selectedFromDate) => {
        console.log('selectedFromDate', selectedFromDate);
        const newFromDate = selectedFromDate[0] || null;
        setSelectedFromDate(newFromDate);

        // If "To" date is before the new "From" date, clear "To" date
        if (selectedToDate && newFromDate && selectedToDate < newFromDate) {
            setSelectedToDate(new Date());
        }
    };

    const handleToDateChange = (date) => {
        console.log('date', date);
        setSelectedToDate(date[0] || null);
    };

    const columns = useMemo(
        () => [
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        User ▼
                    </span>
                ),

                accessorKey: 'u_name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        IP Address ▼
                    </span>
                ),

                accessorKey: 'aul_ipaddress',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Login Date ▼
                    </span>
                ),

                accessorKey: 'login_date',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const login_date = cellProps.row.original.login_date;

                    return (
                        <div className="d-flex align-items-center">
                            {login_date ? (
                                <span>{login_date}</span>
                            ) : (
                                <span className="text-center">-</span>
                            )}
                        </div>
                    );
                },
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Logout Date ▼
                    </span>
                ),

                accessorKey: 'logout_date',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const logout_date = cellProps.row.original.logout_date;

                    return (
                        <div className="d-flex align-items-center">
                            {logout_date ? (
                                <span>{logout_date}</span>
                            ) : (
                                <span className="text-center">-</span>
                            )}
                        </div>
                    );
                },
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Screen Time ▼
                    </span>
                ),

                accessorKey: 'screen_time',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const screen_time = cellProps.row.original.screen_time;

                    return (
                        <div className="d-flex align-items-center">
                            {screen_time !== ' hr  mins ' ? (
                                <span>{screen_time}</span>
                            ) : (
                                <span className="text-center">-</span>
                            )}
                        </div>
                    );
                },

            }
        ],
        []
    );

    // getAdminUserLogList Method
    const getAdminUserLogList = async () => {
        const user = getLoggedInUser()
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        try {
            const data = {
                user_id: Number(user?.uid),
                from_date: moment(selectedFromDate).format("YYYY-MM-DD"),
                to_date: moment(selectedToDate).format("YYYY-MM-DD"),
                u_uid: Number(selectedUser?.value),
                u_urid: Number(selectedUserRole?.value)
            }

            const response1 = await axios.post(url.BASE_URL + 'user-master/getAdminUserLogList', data, config)

            if (response1 && response1.statusFlag === 3) { // user validation
                toast.error(response1.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload()
            }

            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        getAdminUserLogList() // calling same function again getting refresh token
                    }
                }
            }
            if (response1 && response1.data && response1.data.body) {
                setUserLogList(response1?.data?.body?.adminUserList)
            } else {
                toast.error(response1?.data?.body?.message)
            }
        } catch (error) {
            console.error("get Admin User Log API Error:", error);
        }
    };

    // getAdminUserLogList Method
    const getUserList = async () => {
        const user = getLoggedInUser()
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        try {
            const data = {
                user_id: Number(user?.uid),
                login_transaction: 1
            }

            const response1 = await axios.post(url.BASE_URL + 'user-master/getUserList', data, config)

            if (response1 && response1.statusFlag === 3) { // user validation
                toast.error(response1.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload()
            }

            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        getUserList() // calling same function again getting refresh token
                    }
                }
            }
            if (response1 && response1.data && response1.data.body) {
                setUserOptions([{ u_name: "All", uid: "0" }, ...response1?.data?.body?.userList])
                setUserOptionsAll([{ u_name: "All", uid: "0" }, ...response1?.data?.body?.userList])
            } else {
                toast.error(response1?.data?.body?.message)
            }
        } catch (error) {
            console.error("get Admin User Log API Error:", error);
        }
    };

    // getUserRole List
    //Get USerRole list
    const getUserroleListAPI = () => {
        const user = getLoggedInUser()
        setLoading(true)
        dispatch(getUserroleList({ user_id: Number(user.uid) }));
    }

    useEffect(() => {
        var page_indexOfLast = currentPage * perPageData;
        var offset = page_indexOfLast - perPageData;
        getAdminUserLogList()
    }, [filterData, currentPage]);

    useEffect(() => {
        getUserroleListAPI()
        getUserList()
        getAdminUserLogList()
    }, []);

    //Get list from store and set it state values
    useEffect(() => {
        setUserRoleOptions([{ ur_name: "All", urid: "0" }, ...store.userRoleList])
        // console.log('userRoleList', store.userRoleList);
    }, [store.userRoleList])

    const handleChangeUserRole = (selectedOption) => {
        setSelectedUserRole(selectedOption);
        setSelectedUser({ label: 'All', value: 0 })
        var filter_userlog_list = userOptionsAll.filter(e => Number(e.u_urid) === Number(selectedOption?.value))
        setUserOptions(filter_userlog_list)

        if (Number(selectedOption?.value) === 0) {
            setUserOptions(userOptionsAll)
        }

        // filtered_exampaper_option.filter(e => e != undefined);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card className="job-filter">
                                <CardBody>
                                    <Form>
                                        <Collapse isOpen={isFilterOpen} id="collapseExample">
                                            <Row>
                                                <Col lg={2}>
                                                    <div className="mb-3">
                                                        <FormGroup className="mb-4">
                                                            <Label>From Date</Label>
                                                            <InputGroup>
                                                                <Flatpickr
                                                                    value={selectedFromDate}
                                                                    onChange={handleFromDateChange}
                                                                    className="form-control d-block"
                                                                    options={{
                                                                        dateFormat: 'd-m-Y',
                                                                        maxDate: selectedToDate,
                                                                    }}
                                                                    isClearable={true} // Enable clear button
                                                                />
                                                                <button className="btn btn-light" type="button" id="calendar-addon">
                                                                    <i className="bx bx-calendar-event"></i>
                                                                </button>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={2}>
                                                    <div className="mb-3">
                                                        <FormGroup className="mb-4">
                                                            <Label>To Date</Label>
                                                            <InputGroup>
                                                                <Flatpickr
                                                                    value={selectedToDate}
                                                                    onChange={handleToDateChange}
                                                                    className="form-control d-block"
                                                                    options={{
                                                                        minDate: selectedFromDate || undefined,
                                                                        dateFormat: 'd-m-Y',
                                                                    }}
                                                                    isClearable={true} // Enable clear button
                                                                />
                                                                <button className="btn btn-light" type="button" id="calendar-addon">
                                                                    <i className="bx bx-calendar-event"></i>
                                                                </button>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                <Col lg={3}>
                                                    <div className="mb-3">
                                                        <Label>User Role</Label>
                                                        <Select
                                                            value={selectedUserRole}
                                                            onChange={(selectedOption) => {
                                                                handleChangeUserRole(selectedOption)
                                                            }}
                                                            options={userRoleOptions.map((option) => ({
                                                                label: option.ur_name,
                                                                value: option.urid,
                                                            }))}
                                                            className="select2-selection"
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={3}>
                                                    <div className="mb-3">
                                                        <Label>User</Label>
                                                        <Select
                                                            value={selectedUser}
                                                            onChange={(selectedOption) => {
                                                                setSelectedUser(selectedOption);
                                                            }}
                                                            options={userOptions.map((option) => ({
                                                                label: option.u_name,
                                                                value: option.uid,
                                                            }))}
                                                            className="select2-selection"
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={2} >
                                                    <div className="d-flex flex-wrap gap-2 justify-content-start">
                                                        <Button
                                                            type="button"
                                                            style={{ marginTop: 25 }}
                                                            className="btn btn-soft-info"
                                                            onClick={() => getAdminUserLogList()}
                                                        >
                                                            Apply
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            style={{ marginTop: 25 }}
                                                            className="btn btn-soft-danger"
                                                            onClick={() => {
                                                                setFilterData({
                                                                    selectedUser: 0,
                                                                    selectedUserRole: 0,
                                                                    selectedFromDate: new Date(),
                                                                    selectedToDate: new Date()
                                                                })
                                                                setSelectedUser({ label: 'All', value: 0 })
                                                                setSelectedUserRole({ label: 'All', value: 0 })
                                                                setSelectedFromDate(new Date())
                                                                setSelectedToDate(new Date())
                                                                setUserOptions(userOptionsAll)
                                                            }
                                                            }
                                                        >
                                                            Clear
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {isLoading ?
                        <Spinners setLoading={setLoading} />
                        :
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={userLogList || []}
                                        isPagination={true}
                                        pagination="pagination"
                                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                    />
                                    {/* <PaginationTableContainer
                                        columns={columns}
                                        data={userLogList || []}
                                        isPagination={true}
                                        // isGlobalFilter={true}
                                        perPageData={perPageData}
                                        totalLength={totalCount}
                                        currentPage={currentPage}
                                        // SearchPlaceholder="Search..."
                                        //   setCurrentPage={setCurrentPage}
                                        pagination="pagination"
                                        // isCustomPageSize={true}
                                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                        theadClass="table-light"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                    /> */}
                                </CardBody>
                            </Card>
                        </Col>
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default LoginTransaction;