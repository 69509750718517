import {
  GET_SUBJECTS_SUCCESS,
  ADD_SUBJECT_SUCCESS,
  DELETE_SUBJECT_SUCCESS,
  RESET_STORE_SUBJECT,
  UPDATE_VALUE,
  RESET_SUBJECT
} from "./actionTypes"

const INIT_STATE = {
  subjectList: [],
  subject_statusFlag: 0,
  subject_details: []
}

const CLEAR_SUBJECT = {
  subject_details: []
}

const Subject = (state = INIT_STATE, action) => {
  console.log(action.type, action.payload, 'reducer')
  switch (action.type) {
    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjectList: action.payload.subjectList,
        subject_statusFlag: action.payload.statusFlag
      }
    case ADD_SUBJECT_SUCCESS:
      return {
        ...state,
        subject_statusFlag: action.payload.statusFlag,
        subject_details: action.payload.subjectDetails
      };
    case DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        subject_statusFlag: action.payload.statusFlag,
      };
    case RESET_STORE_SUBJECT:
      return {
        ...state,
        subject_statusFlag: action.payload.statusFlag,
      };
    case UPDATE_VALUE:
      return { ...state, subject_details: [] };

    case RESET_SUBJECT:
      return CLEAR_SUBJECT;

    default:
      return state
  }
}

export default Subject