import {
  GET_SUBJECTSSUBCATEGORY,
  GET_SUBJECTSSUBCATEGORY_SUCCESS,
  ADD_SUBJECTSUBCATEGORY,
  ADD_SUBJECTSUBCATEGORY_SUCCESS,
  DELETE_SUBJECTSUBCATEGORY,
  DELETE_SUBJECTSUBCATEGORY_SUCCESS,
  RESET_STORE_SUBJECTSUBCATEGORY
} from "./actionTypes"

export const getsubjectsubcategory = (data) => ({
  type: GET_SUBJECTSSUBCATEGORY,
  payload: data
})
export const getsubjectsubcategorySuccess = subjectSubCatList => ({
  type: GET_SUBJECTSSUBCATEGORY_SUCCESS,
  payload: subjectSubCatList,
})
export const addsubjectsubcategory = data => ({
  type: ADD_SUBJECTSUBCATEGORY,
  payload: data,
})

export const addsubjectsubcategorySuccess = data => ({
  type: ADD_SUBJECTSUBCATEGORY_SUCCESS,
  payload: data,
})

export const deletesubjectsubcategory = data => ({
  type: DELETE_SUBJECTSUBCATEGORY,
  payload: data,
})

export const deletesubjectsubcategorySuccess = data => ({
  type: DELETE_SUBJECTSUBCATEGORY_SUCCESS,
  payload: data,
})

export const resetsubjectsubcategory = data => ({
  type: RESET_STORE_SUBJECTSUBCATEGORY,
  payload: data,
})



