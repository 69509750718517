import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import TableContainer from '../../../components/Common/TableContainer';
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import { getmasterlist } from "../../../store/actions";
import { getLoggedInUser, getRefreshToken } from "../../../helpers/fakebackend_helper"
import { useDispatch } from "react-redux";
import * as constants from "../../../constants/layout"
import { logoutUser } from "store/auth/login/actions";
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody } from "reactstrap";
import axios from "axios";
import * as url from "../../../../src/helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const TotalQuestionsList = () => {
    document.title = "Masters | Total Questions";

    const dispatch = useDispatch();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const user = getLoggedInUser()
    const [submitbtn, setSubmitbtn] = useState('')
    const [inputFocus, setInputFocus] = useState(false);
    const inputRef = useRef(null);
    const [deleteTrigger, setDeleteTrigger] = useState(false)
    const [noOfQuestions, setNoofQuestions] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [noOfQuestion, setNoofQuestion] = useState();
    const [isLoading, setLoading] = useState(false);
    const [topic, setTopic] = useState(null);
    const [canView, setCanView] = useState(false)
    const [canCreate, setCanCreate] = useState(false)
    const [canModify, setCanModify] = useState(false)
    const [canRemove, setCanRemove] = useState(false)
    const inputtopRef = useRef(null);
    const [statusFlag, setStatusFlag] = useState(0)
    const [totalEdit, setTotalEdit] = useState();

    const disableButton = () => {
        setButtonDisabled(true);
    };

    const enableButton = () => {
        setButtonDisabled(false);
    };

    useEffect(() => {
        getNoofQuestions()
        document.getElementById('headerTitle').innerHTML = 'Total Questions';
    }, [dispatch]);

    useEffect(() => {
        if (modal && inputRef.current && inputFocus) {
            inputRef.current.focus();
            setInputFocus(false);
        }
    }, [modal, inputFocus]);


    const getNoofQuestions = async () => {

        const data = {
            user_id: Number(user.uid),
        };

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(url.BASE_URL + 'master/getTotalQuestionList', data, config);
            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        await getNoofQuestions() // calling same function again getting refresh token
                    }
                }
            }
            if (response && response.statusFlag === 3) {
                toast.error(response.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload();
                return;
            }

            if (response && response.data && response?.data?.body?.totalQuestionList) {
                setNoofQuestions(response?.data?.body?.totalQuestionList)
            } else {
                setNoofQuestions([])

            }
        } catch (error) {
            // Handle error
            console.error("Get No of question list Error:", error);
        }
    };






    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            noofquestions: (topic && topic.subCategory) || ''
        },
        validationSchema: Yup.object({
            noofquestions: Yup.string().required("Please Enter Total Questions"),
        }),
        onSubmit: (values) => {
            console.log('value', values)
            setLoading(true)
            if (isEditing) {
                const newData = {
                    user_id: Number(user.uid),
                    process_type: 'update',
                    total_questions: Number(values?.noofquestions),
                    tq_stsid: 1,
                    tqid: Number(totalEdit?.tqid)
                };
                saveNoofQuestion(newData)

            } else {

                var total = values?.noofquestions
                const newData = {
                    user_id: Number(user.uid),
                    process_type: "save",
                    total_questions: Number(total),
                    tq_stsid: 1,
                    tqid: 0

                };
                saveNoofQuestion(newData)
            }

        },
    });

    const saveNoofQuestion = async (data) => {
        setLoading(true)

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'master/saveTotalQuestions', data, config)

        if (response1 && response1.statusFlag === 3) {
            setLoading(false)
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            sessionStorage.removeItem("SESSION_KEY")
            window.location.reload()
            return
        }
        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            setLoading(false)
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    saveMaterial() // calling same function again getting refresh token
                }
            }
        }
        if (response1 && response1.data && response1.data.body?.statusFlag == 3) {
            setLoading(false)

            // user validation
            // toast.error(response1.message, { autoClose: 2000 });
            toast.error(response1.data.body.message, { autoClose: 2000 });

            return
        }

        if (response1 && response1.data && response1.data.body && response1.data.body?.statusFlag == 1) {
            toast.success(response1.data.body.message, { autoClose: 2000 });
            setStatusFlag(response1?.data?.body?.statusFlag)
            if (submitbtn != 'savecontinue') {
                setModal(false)
                getNoofQuestions()

            } else {
                getNoofQuestions()
                setModal(true)
                validation.resetForm()
                // validation.setFieldValue("noofquestions", "")
            }


        } else if (response1 && response1.data && response1.data.body && response1.data.body?.statusFlag == 2) {
            setStatusFlag(response1?.data?.body?.statusFlag)
            setLoading(false)
            toast.error(response1.data.body.message, { autoClose: 2000 });

        } else {
            setStatusFlag(response1?.data?.body?.statusFlag)

            setLoading(false)
            toast.error(response1.data.message, { autoClose: 2000 });
        }

    }


    const customStyles = {
        option: (styles, state, data) => { // fixed
            return {
                ...styles,
                backgroundColor: state.isDisabled
                    ? undefined
                    : state.isSelected
                        ? '#a6b0cf57'
                        : state.isFocused
                            ? '#556ee6'
                            : 'white',

                color: state.isDisabled
                    ? '#ccc'
                    : state.isSelected
                        ? 'black'
                        : state.isFocused
                            ? 'white'
                            : 'black',

                cursor: state.isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled
                        ? state.isSelected
                            ? '#556ee6'
                            : '#556ee6'
                        : undefined,
                },
                fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
            };
        }
    };

    const [deleteModal, setDeleteModal] = useState(false);

    // After api call success stop loading
    useEffect(() => {
        enableButton()
        if (statusFlag === 1) {
            if (deleteTrigger) { // delete then refresh list
                setDeleteTrigger(false)
                setDeleteModal(false);
                setNoofQuestion('');
            }
            setLoading(false)
        } else if (statusFlag === 2) {
            if (submitbtn) {
                setSubmitbtn('')
            }
            if (deleteTrigger) {
                setDeleteTrigger(false)
                setDeleteModal(false);
                setNoofQuestion('');
            }
            setLoading(false)
        }
    }, [statusFlag])

    useEffect(() => {
        if (modal && inputtopRef.current && inputFocus) {
            inputtopRef.current.focus();
            setInputFocus(false);
        }
    }, [modal, inputFocus]);

    useEffect(() => {
        console.log('submitbtn:', submitbtn);
        console.log('inputFocus:', inputFocus);
        if (submitbtn === 'savecontinue' && inputtopRef.current && !inputFocus) {
            console.log('Focusing input...');
            inputtopRef.current.focus();
            setInputFocus(false);
        }
    }, [submitbtn, inputFocus]);



    useEffect(() => {
        if (modal) {
            setInputFocus(true);
        }
    }, [modal]);


    const getmasterlistapi = () => {

        dispatch(getmasterlist({ user_id: Number(user.uid) }));
    }

    useEffect(() => {
        getmasterlistapi()
        document.getElementById('headerTitle').innerHTML = 'Total Questions';
    }, [dispatch]);

    const onClickDelete = (topicData) => {
        setNoofQuestion(topicData?.tqid);
        setDeleteModal(true);
    };

    const handleDeleteOrder = async () => {
        setLoading(true);
        const data = {
            user_id: Number(user.uid),
            tqid: Number(noOfQuestion)
        };

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(url.BASE_URL + 'master/deleteTotalQuestions', data, config);
            if (response && response.statusFlag === 3) {
                toast.error(response.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await handleDeleteOrder();
                    }
                }
            }

            if (response?.data?.body?.statusFlag === 1) {
                toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error(error);
        } finally {
            setLoading(false);
            setDeleteModal(false);


            await getNoofQuestions(0);
        }
    };


    const columns = useMemo(
        () => [
            {
                header: 'Actions',
                width: '8%',
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {cellProps.row.original.c_name == null && user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.Topic && f.urp_type === constants.menuTypes.Modify) && (
                                    <div
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => {
                                            const topicData = cellProps.row.original;
                                            handleEditClick(topicData);
                                        }}
                                    >
                                        <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                            Edit
                                        </UncontrolledTooltip>
                                    </div>)}
                            {cellProps.row.original.c_name == null && user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.Topic && f.urp_type === constants.menuTypes.Remove) && (
                                    <div
                                        to="#"
                                        className="btn btn-sm btn-soft-danger"
                                        onClick={() => {
                                            const topicData = cellProps.row.original; onClickDelete(topicData);
                                        }}>
                                        <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                            Delete
                                        </UncontrolledTooltip>
                                    </div>)}
                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },

            {
                header: (

                    <span className="d-flex gap-3 justify-content-center" style={{ cursor: 'pointer' }}>
                        Total Questions ▼
                    </span>

                ),

                accessorKey: 'total_questions',

                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center" style={{ cursor: 'pointer' }}>
                            {cellProps.row.original.total_questions}
                        </div>
                    );
                }
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Created By ▼
                    </span>
                ),

                accessorKey: 'tq_created',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Updated By ▼
                    </span>
                ),

                accessorKey: 'tq_updated',
                // enableColumnFilter: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const mk_stsid = cellProps.row.original.tq_updated;

                    return (
                        <div className="d-flex align-items-center">
                            {mk_stsid ? (
                                <span>{mk_stsid}</span>

                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    );
                },
            }

        ],
        []
    );

    const handleAddClick = () => {
        enableButton()
        setTotalEdit(null)
        validation.resetForm();
        setIsEditing(false)
        setNoofQuestion('')
        toggle();
    };

    const handleEditClick = (topicData) => {
        setModal(true)
        enableButton()
        setIsEditing(true)
        validation.setFieldValue("noofquestions", topicData?.total_questions)
        setTotalEdit(topicData)
    };



    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    useEffect(() => {
        if (user && user.user_previleges) {
            let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.Topic)
            if (page_menu_list) {
                setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
                setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
                setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
                setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
            }
        }
    }, [user]);

    return (

        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={noOfQuestions || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                isAddButton={canCreate}
                                                buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"
                                                handleUserClick={handleAddClick}

                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                    <Modal style={{ maxWidth: '300px', width: '100%' }}
                        isOpen={modal} toggle={toggle} backdrop="static">
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEditing ? "Edi Total Questions" : "Add Total Questions"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">


                                        <div className="mb-3">
                                            <Label>Total Questions<span style={{ color: 'red' }}>*</span></Label>
                                            <Input
                                                className="rightAlignText"
                                                name="noofquestions"
                                                type="text"
                                                maxLength={constants.textboxLength.no_ques}
                                                validate={{
                                                    required: { value: true },
                                                }} autoComplete="off"

                                                innerRef={inputtopRef}

                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.noofquestions || ""}
                                                invalid={
                                                    validation.touched.noofquestions && validation.errors.noofquestions ? true : false
                                                }
                                                onKeyPress={(e) => {
                                                    const char = String.fromCharCode(e.which);
                                                    if (!/[0-9]/.test(char)) {
                                                        e.preventDefault(); // Prevents non-numeric characters
                                                    }
                                                }}
                                            />
                                            {validation.touched.noofquestions && validation.errors.noofquestions ? (
                                                <FormFeedback type="invalid">{validation.errors.noofquestions}</FormFeedback>
                                            ) : null}
                                        </div>



                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success"
                                                type="submit"
                                                className="save-user"
                                                disabled={isButtonDisabled}
                                                onClick={() => {
                                                    setSubmitbtn('save')
                                                }}
                                            >
                                                {!!isEditing ? "Update" : "Save"}
                                            </Button>
                                            {!isEditing && (
                                                <Button color="primary" type="submit" className="save-and-continue-user"
                                                    onClick={() => {
                                                        setSubmitbtn('savecontinue')
                                                    }}
                                                    disabled={isButtonDisabled}
                                                    style={{ marginLeft: '10px' }}>
                                                    Save and Continue
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
TotalQuestionsList.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default TotalQuestionsList;